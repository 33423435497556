import React, { useContext, useEffect, useState } from 'react';
import { requestForOTP, verifyOTP } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../Context/authContext';
import { Box } from '@mui/material';
import KenLoader from '../../../Components/KenLoader';
import { LOGIN_BG } from '.';
import MobileLoginBG from '../../../Assets/Svg/login/login-mobile-bg.svg';
import Verify from '../common/Verify';
import { handleError } from '../util';
import { makeStyles } from '@mui/styles';
import routes from '../../../Constants/routes';
import { useSnackbar } from 'notistack';
import validator from 'validator';
import logo from "../../../Assets/Images/Turbostart - White.png"

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    // width: '100%',
    // marginTop: '15vh',
    backgroundImage: `url(${LOGIN_BG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
      '@media (max-width: 800px)': {
        display: 'flex',
        padding: '35px 55px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundImage: `url(${LOGIN_BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
          backgroundImage: `url(${MobileLoginBG})`,
        },
      },
      '@media (max-width: 600px)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: '10px !important',
        background: 'none',
      },
    },
  },
  logo: {
    margin: '18px auto 54px auto',
    '@media (max-width: 800px)': {
      margin: '18px auto 34px auto',
    },
  },
}));
export default function LoginOTP(props) {
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(null);
  const navigate = useNavigate();
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!navigateState?.alumniId) {
      navigate(routes.login);
    } else {
      setIsEmail(validator.isEmail(navigateState.currentId));
    }
  }, [navigate, navigateState?.currentId, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.currentId,
      });

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || "Something went wrong", {
          variant: "error",
        });
      }

      const data = {
        user: {
          id: res?.contactId,
          Email: res?.contactEmail,
          Id: res?.contactId,
          Name: res?.contactName,
          accessToken: {
            accessToken: res?.accessToken,
          },
          refreshToken: {
            refreshToken: res?.refreshToken,
          },
        },
      }
      dispatch({
        type: 'LOGIN',
        payload: { ...data }
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {typeof isEmail !== 'boolean' ? (
        <KenLoader />
      ) : (
        <Box className={classes.container}>
          <img
            src={logo}
            alt="Logo"
            height={"42px"}
            width={"auto"}
            className={classes.logo}
          />
          <form>
            <Verify
              loading={loading}
              from="loginOTP"
              title="Verify OTP"
              subTitle={
                <>
                  <span>
                    {isEmail
                      ? 'Please enter the OTP sent to your registered Email ID.'
                      : 'Please enter the OTP sent on your registered mobile number'}
                  </span>
                  {!isEmail && (
                    <strong style={{ marginLeft: '5px' }}>
                      *******{navigateState.currentId.slice(-3)}.
                    </strong>
                  )}
                </>
              }
              submitText="Verify & Log In"
              onVerify={(otp) => {
                onSubmit({ otp });
              }}
              resendOTP={async () => {
                await requestForOTP({ id: navigateState.currentId });
              }}
            ></Verify>
          </form>
        </Box>
      )}
    </>
  );
}
