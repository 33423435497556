import { AccountLink, SubTitle, Title } from '../common';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import {
  THEME_NAMES,
  activeTheme,
  getActiveTheme,
  isActiveTheme,
} from '../../../Utils/Themes/themeHelper';

import Facebook from '../../../Assets/Svg/facebook.svg';
import Google from '../../../Assets/Svg/google.svg';
import JDInstitute from '../../../Assets/Images/client/jd-logo.png';
import VenturiseLogo from '../../../Assets/Images/client/venturise-logo.png';
import StpiLogo from '../../../Assets/Images/client/stpi-logo.png';
import JDLoginBG from '../../../Assets/Svg/login/jd-bg.svg';
import Ken42LoginBG from '../../../Assets/Svg/login/ken42-bg.svg';
import KenButton from '../../../Components/KenButton';
import KenInput from '../../../Components/KenInput';
import LinkedIn from '../../../Assets/Svg/linkedin.svg';
import ManipalInstitute from '../../../Assets/Images/client/manipal-logo.svg';
import ManipalLoginBG from '../../../Assets/Svg/login/manipal-bg.svg';
import NSOMInstitute from '../../../Assets/Images/client/nsom-logo.png';
import NSOMLoginBG from '../../../Assets/Svg/login/ken42-bg.svg';
import VenturiseLoginBG from '../../../Assets/Svg/login/ken42-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login/login-mobile-bg.svg';
import SocialIcons from '../SocialIcon';
import { handleError } from '../util';
import { makeStyles } from '@mui/styles';
import { requestForOTP } from '../api';
import routes from '../../../Constants/routes';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import validator from 'validator';
import logo from "../../../Assets/Images/Turbostart - White.png"

const isKen42Theme = isActiveTheme(THEME_NAMES.KEN42);

export const LOGIN_BG = {
  [THEME_NAMES.JDIFT]: JDLoginBG,
  [THEME_NAMES.MANIPAL]: ManipalLoginBG,
  [THEME_NAMES.NSOM]: NSOMLoginBG,
  [THEME_NAMES.KEN42]: Ken42LoginBG,
  [THEME_NAMES.VENTURISE]: VenturiseLoginBG
}[getActiveTheme()];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url(${"clients/" + getActiveTheme() + "/login-backgroud.svg"})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
    '@media (max-width: 800px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      backgroundImage: `url(${LOGIN_BG})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${MobileLoginBG})`,
      },
    },
    '@media (max-width: 600px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      margin: '10px',
      background: 'none',
    },
    '@media (max-width: 320px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      background: 'none',
    },
  },
  logo: {
    margin: '18px auto 54px auto',
    '@media (max-width: 800px)': {
      margin: '18px auto 34px auto',
    },
  },
  loginContent: {
    minWidth: '500px',
    minHeight: '520px',
    'box-shadow':
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '16px',
    padding: '48px 84px',
    paddingTop: 0,
    '@media (max-width: 800px)': {
      minWidth: '250px',
      minHeight: '400px',
      'box-shadow':
        '0px 5px 10px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      'border-radius': '16px',
      padding: '35px 55px',
      paddingTop: 0,
    },
    '@media (max-width: 600px)': {
      minWidth: '150px !important',
      minHeight: '250px !important',
      'box-shadow':
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      'border-radius': '26px',
      padding: '20px 30px !important',
      paddingTop: 0,
    },
    '@media (max-width: 320px)': {
      minWidth: '150px !important',
      minHeight: '300px !important',
      'box-shadow':
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      'border-radius': '16px',
      padding: '20px 20px !important',
      paddingTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: '8px',
      minWidth: '360px',
    },
  },
  title: {
    marginBottom: '16px',
    color: 'white',
    '@media (max-width: 800px)': {
      marginBottom: '10px',
    },
    '@media (max-width: 320px)': {
      marginBottom: '10px',
      fontSize: '20px',
      fontWeight: '600',
      marginLeft: '36%'
    },
  },
  subTitle: {
    marginBottom: '32px',
    color: 'white',
    '@media (max-width: 320px)': {
      marginBottom: '20px',
      textAlign: 'center',
    },
  },
  inputFieldLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    'text-transform': 'capitalize',
    color: '#fff',
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
  signupWith: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.neutral100,
  },
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
    '& img.google': {
      width: '37px',
      height: '40px',
    },
  },
}));

const mobileOrEmail = (id) => {
  //Note strict mode will enable only validation with country code
  if (
    validator.isEmail(id) ||
    validator.isMobilePhone(id, 'en-IN', { strictMode: true })
  ) {
    return true;
  }

  return 'Please enter valid email.';
};

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await requestForOTP(data);
      console.log(res);
      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      navigate(`../${routes.loginOTP}`, {
        state: {
          alumniId: res.contactId,
          currentId: data.id,
        },
        replace: true
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
      // setTimeout(() => navigate(routes.signup), 3000);
    } finally {
      setLoading(false);
    }
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      id: '',
    },
  });

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          className={classes.loginContent}
          display={'flex'}
          flexDirection="column"
          justifyContent={'flex-start'}
          alignItems="flex-start"
        >
          <img
            src={logo}
            alt="Logo"
            height={"42px"}
            width={"auto"}
            className={classes.logo}
          />
          <Title className={classes.title} text="Log In" />
          <SubTitle
            className={classes.subTitle}
            text="Please enter your login credentials below to start."
          />
          <Box mb={4} width="100%">
            <Controller
              name="id"
              control={control}
              rules={{ required: true, validate: { mobileOrEmail } }}
              render={({ field, fieldState: { error } }) => (
                <KenInput
                  // required
                  name="id"
                  label="Email Id"
                  placeholder="Please enter your Email Id"
                  labelClassName={classes.inputFieldLabel}
                  error={error?.message || error?.type}
                  {...field}
                />
              )}
            />
          </Box>
          <KenButton
            variant="primary"
            buttonClass={classes.verify}
            type="submit"
            disabled={loading}
          >
            Sign In
          </KenButton>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '49%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
          {/* <Box
            display={'flex'}
            justifyContent="center"
            width="100%"
            marginBottom="40px"
          >
            <AccountLink
              text="Don’t have an account?"
              actionText={'Sign up'}
              onAction={() => navigate(routes.signup)}
            />
          </Box> */}
          <Box
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            width="100%"
          >
            {/* {getActiveTheme() !== "MANIPAL" &&
              <>
                <Box marginRight={'12px'}>
                  <Typography className={classes.signupWith}>
                    Or Sign Up with
                  </Typography>
                </Box>
                <SocialIcons />
              </>
            } */}
          </Box>
        </Box>
      </form>
    </Box>
  );
}
