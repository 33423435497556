import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import IconButton from "@mui/material/IconButton";
import { useEffect } from 'react';
import { RxCross1 } from "react-icons/rx";
import { CgFileDocument } from "react-icons/cg";
import { BsChat } from 'react-icons/bs';
import { useLocation } from 'react-router';
import { getApplicationData, postFeedback } from '../../Utils/apiServices';
import { useContext } from 'react';
import { AuthContext } from '../../Context/authContext';
import { useSnackbar } from 'notistack';

const ApplicationDetails = React.lazy(() =>
  import('./ApplicationDetails/index')
);
const FeedBack = React.lazy(() => import('./FeedBack/index'));
const PitchDesk = React.lazy(() => import('./PitchDesk/index'));
const OnePager = React.lazy(() => import('./OnePager/index'));
const Header = React.lazy(() => import('../Header/index'));

const StartUps = () => {
  const root = document.querySelector(':root');
  const primary = getComputedStyle(root).getPropertyValue('--primaryTheme');
  const containePrimary = getComputedStyle(root).getPropertyValue('--appTheme')
  const profileMain =
    getComputedStyle(root).getPropertyValue('--appTheme-shade');
  const white = getComputedStyle(root).getPropertyValue('--appTheme');
  const white1 = getComputedStyle(root).getPropertyValue('--light');
  const greencolor = getComputedStyle(root).getPropertyValue('--greenColor');

  const useStyles = makeStyles({
    tabs: {
      fontSize: '15px !important',
      color: `${primary} !important`,
      '&.Mui-selected': {
        color: `${primary} !important`,
        fontWeight: '500',
        position: 'relative',
      },
    },
    tab: {
      textTransform: 'capitalize',
      '& .MuiTabs-indicator': {
        backgroundColor: `${greencolor}`,
      },
      '& .MuiTab-root.Mui-selected': {
        color: `${white}`,
      },
    },
    tabBg: {
      backgroundColor: `${profileMain}`,
    },
    input: {
      color: `${white1}`,
      backgroundColor: '#25272D',
      border: '1px solid rgba(228, 228, 228, 0.50)',
      '::placeholder': {
        color: `${white1}`,
      },
    },
  });

  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [data, setData] = React.useState();
  // const [open, setOpen] = useState(false);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const handleTabs = (e, value) => {
    setTabValue(value);
  };
  const {
    state: { user },
  } = useContext(AuthContext);


  const handleModalClose = () => {
    setPopupOpen(!popupOpen);
  }

  function reorderCommentsFirst(inputArray) {
    return inputArray.map((section) => {
      const parameters = section?.parameters;
      const commentIndex = parameters.findIndex(
        (param) => param.questionType === 'Comment'
      );

      if (commentIndex !== -1) {
        const commentParameter = parameters.splice(commentIndex, 1)[0];
        parameters.unshift(commentParameter);
      }

      return section;
    });
  }
  function reorderParametersByOrder(parameters) {
    return parameters.slice().sort((a, b) => a.order - b.order);
  }

  function reorderParametersInSections(feedbackArray) {
    return feedbackArray.map((section) => {
      const reorderedParameters = reorderParametersByOrder(section.parameters);
      return { ...section, parameters: reorderedParameters };
    });
  }

  useEffect(() => {
    getApplicationData(
      location?.state?.Application__c,
      location?.state?.Review_Round__c,
      user?.Id
    ).then((res) => {
      let data = { ...res?.data };
      data.attachments[0].link = data?.application?.Pitch_Deck_URL__c;
      delete data?.application?.attributes;
      delete data?.application?.SystemModstamp;
      delete data?.application?.Startup_Application_Form__c;
      delete data?.application?.Applicant__c;
      delete data?.application?.Id;
      delete data?.application?.Pitch_Deck_URL__c;
      delete data?.application?.RecordTypeId;
      delete data?.application?.hed__Applicant__c;
      delete data?.application?.hed__Application_Status__c;
      if (data?.feedbackScore) {
        data.feedback = JSON.parse(data?.feedbackScore?.Evaluation_Response__c);
      } else {
        data.feedback = reorderParametersInSections(data?.feedback);
      }
      setData(data);
    });
  }, []);

  console.log(data);

  const handleTabPanels = (tab) => {
    switch (tab) {
      // case 0:
      //   return <ApplicationDetails data={data} />;
      case 0:
        return <PitchDesk data={data} />;
      case 1:
        return <OnePager data={data} />;
      case 2:
        return (
          <FeedBack
            data={data}
            PanelId={location?.state?.PanelId}
            setData={setData}
          />
        );
      // case 3:
      //   return <FeedBack />;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    setPlacement(isMobileScreen ? 'auto' : 'top-start');
  };

  const handleData = (index, ind, value) => {
    let dataCopy = { ...data };
    dataCopy.feedback[index].parameters[ind].questionResponse = value;
    setData(dataCopy);
  };

  useEffect(() => {
    if (tabValue === 2) {
      setOpen(false);
    }
  }, [tabValue]);

  const handleSave = () => {
    let flag = false;
    data?.feedback?.forEach((el) => {
      el.parameters?.forEach((elem) => {
        if (elem.questionResponse === null) {
          flag = true;
        }
      });
    });

    if (flag === true) {
      return enqueueSnackbar('Please fill all fields', { variant: 'warning' });
    }

    let payload = {
      memberId: user?.Id,
      panelAssociationId: location?.state?.PanelId,
      response: data?.feedback,
    };

    postFeedback(payload).then((res) => {
      console.log(res);
      if (res?.success) {
        enqueueSnackbar('The feedback has been posted for this application', {
          variant: 'success',
        });
        setOpen(false);
        // navigate('/dashboard')
      }
    });
  };

  return (
    <>
      <Modal
        open={popupOpen}
        onClose={() => {
          handleModalClose();
        }}
      >
        <Box component={"div"} width={isMobileScreen ? "100%" : "60%"} height={'70vh'} overflow="scroll" className={`py-4 px-4 border-0 rounded-2 common-modal `} bgcolor={containePrimary}>
          <div className="d-flex justify-content-between pt-1 pb-3">
            <h6 className="font-primary-16-bold m-0 p-0">Scoring Criteria</h6>
            <IconButton
              onClick={() => {
                handleModalClose();
              }}
            >
              <RxCross1 color="white" size={"16px"} />
            </IconButton>
          </div>
          <li className="font-white-14-normal lh-base">Market Opportunity (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Limited market potential with niche appeal.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Moderate market potential, but with significant limitations.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Promising market opportunity with some competition.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Attractive market opportunity with room for growth.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Exceptional market opportunity with clear demand and growth potential.</ul>
          <li className="font-white-14-normal lh-base mt-4">Team Strength (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Inexperienced or inadequately skilled team.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Limited relevant experience, with gaps in key roles.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Competent team with relevant expertise.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Experienced and well-rounded team with a track record.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Exceptional team with a strong track record and complementary skills.</ul>
          <li className="font-white-14-normal lh-base mt-4">Product/Technology Innovation (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Mimic or minimal innovation.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Incremental improvements on existing solutions.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Some innovation, but not groundbreaking.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Innovative product/technology with unique features.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Highly innovative, disruptive, and game-changing product/technology.</ul>
          <li className="font-white-14-normal lh-base mt-4">Traction and Milestones (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Limited user adoption or minimal progress.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Some early traction, but far from milestones.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Steady growth and achieving key milestones.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Strong traction, clear product-market fit, and milestones met.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Rapid growth, impressive user adoption, and exceeding milestones.</ul>
          <li className="font-white-14-normal lh-base mt-4">Business Model and Monetization (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Unclear or unsustainable business model.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Basic monetization strategy with uncertainties.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Viable business model with potential for improvement.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Strong monetization strategy and sustainable revenue streams.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Highly scalable and profitable business model with a clear path to revenue growth.</ul>
          {/* <h6 className="font-white-14-normal lh-base mt-3 mb-3">
            Please note that judges are requested to assign a score of between 1 to 5 points for each criterion, with 1 indicating the lowest score and 5 indicating the highest score. This scoring mechanism will assist in a comprehensive evaluation of the participating startups.          </h6> */}
        </Box>
      </Modal>
      <Header />
      <Box component={'div'} className="PageContainer">
        <Box
          component={'div'}
          bgcolor={`${profileMain}`}
          className={`w-100 d-flex ${isMobileScreen && 'flex-wrap'
            } justify-content-between align-items-center ${isMobileScreen ? 'gap-3' : 'gap-5'
            } p-3 rounded-3 mb-4 shadow-sm`}
        >
          <div className="d-flex align-items-center flex-nowrap gap-3">
            {/* <Box component={"img"} src={location.state.logo} height={isMobileScreen ? "120px" : "200px"} width={isMobileScreen ? "120px" : "200px"} className="shadow-sm" sx={{ objectFit: "contain" }} loading="lazy" /> */}
            {isMobileScreen && (
              <div>
                <h6 className="font-white-20-bold m-0 p-0 ">
                  {location.state.name}
                </h6>
                <h6 className="font-white-16-normal m-0 p-0 my-1">
                  {location.state.designation}
                </h6>
              </div>
            )}
          </div>
          <div className="w-100">
            <div
              className={`d-flex flex-wrap ${!isMobileScreen && 'justify-content-between'
                } gap-2 w-100`}
            >
              {!isMobileScreen && (
                <div>
                  <h6 className="font-white-20-bold m-0 p-0 ">
                    {data?.application?.Startup_Name__c}
                  </h6>
                  <h6 className="font-white-16-normal m-0 p-0 my-1">
                    {location.state.designation}
                  </h6>
                </div>
              )}
              <div>
                <h6 className="font-grey-12-bold m-0 p-0 my-1">Sector</h6>
                <h6 className="font-white-14-normal m-0 p-0 my-1">
                  {data?.application?.Sector__c}
                </h6>
              </div>
              <div>
                <h6 className="font-grey-12-bold m-0 p-0 my-1">Location</h6>
                <h6 className="font-white-14-normal m-0 p-0 my-1">
                  {data?.application?.Country__c}
                </h6>
              </div>
              {/* <div>
                <h6 className="font-grey-12-bold m-0 p-0 my-1">
                  Scoring Instructions
                </h6>
                <h6 className="font-white-14-normal m-0 p-0 my-1">
                  {data?.application?.Startup_Website_URL__c}
                </h6>
              </div> */}
              <Box component={'div'} color={'#A3AAD0'} fontSize={'16px'} className="col-5  text-end cursor-pointer">
                <p className="mt-3" onClick={() => { handleModalClose(); }} >
                  <CgFileDocument />
                  &nbsp;Scoring Criteria
                </p>
              </Box>
            </div>
            <div className="w-100 mt-3">
              <h6 className="font-grey-12-bold m-0 p-0 my-1">Elevator Pitch</h6>
              <h6 className="font-white-14-normal m-0 p-0 my-2">
                {data?.application?.Company_Synopsis__c}
              </h6>
            </div>
          </div>
        </Box>
        <Box
          component={'div'}
          className={`card shadow-sm rounded-4 ${isMobileScreen ? 'p-0' : 'p-2'
            } border-0 mt-1 ${classes.tabBg}`}
        >
          <Tabs
            variant="scrollable"
            value={tabValue}
            className={classes.tab}
            onChange={(e, value) => {
              handleTabs(e, value);
            }}
          >
            {/* <Tab
              label="Application Details"
              {...a11yProps(0)}
              className={`p-0 px-3 text-capitalize ${classes.tabs}`}
            /> */}
            <Tab
              label="Pitch Deck"
              {...a11yProps(0)}
              className={`p-0 px-3 text-capitalize ${classes.tabs}`}
            />
            <Tab
              label="One Pager"
              {...a11yProps(1)}
              className={`p-0 px-3 text-capitalize ${classes.tabs}`}
            />
            <Tab
              label="Feedback"
              {...a11yProps(2)}
              className={`p-0 px-3 text-capitalize ${classes.tabs}`}
            />
            {/* <Tab label="Additional Documents" {...a11yProps(2)} className={`p-0 px-3 text-capitalize ${classes.tabs}`} /> */}
          </Tabs>
          <div className="divider m-0 p-0" />
          <div className="card-body px-3 py-4 w-100">
            {handleTabPanels(tabValue)}
          </div>
        </Box>
      </Box>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              component={'div'}
              className={`card border border-2 rounded-2 shadow-sm${isMobileScreen ? 'mx-3' : 'mx-2'
                } p-3`}
              width={isMobileScreen ? '60%' : '630px'}
              height={isMobileScreen ? '500px' : 'fit-content'}
              overflow={'scroll'}
              sx={{ backgroundColor: '#101115' }}
            >
              <div className="w-100">
                <h1 className="font-white-20-bold mb-4">Feedback</h1>
                {/* <TextField fullWidth multiline rows={5} placeholder="Had a wonderful time working with them." variant="outlined" type="text" InputProps={{ className: classes.input }} /> */}
                <Box overflow={'scroll'} width={'100%'} maxHeight={'69vh'}>
                  {data?.feedback?.map((el, index) => {
                    return (
                      <>
                        {el?.parameters?.map((elem, ind) => {
                          if (elem.questionType === 'Rating') {
                            return (
                              <>
                                <h1 className="font-white-14-bold mt-3">
                                  {elem?.questionLabel}
                                </h1>
                                <Box component={'div'} className="d-flex mt-4">
                                  <div>
                                    <Box
                                      component={'button'}
                                      borderRadius={'4px'}
                                      className={'font-white-16-bold'}
                                      marginRight={'50px'}
                                      padding={'15px 20px'}
                                      border={
                                        elem.questionResponse == 1
                                          ? '1px solid rgba(101, 178, 110, 1)'
                                          : '1px solid #F4FAFF'
                                      }
                                      bgcolor={'#101115'}
                                      onClick={() => handleData(index, ind, 1)}
                                    >
                                      1
                                    </Box>
                                  </div>
                                  <div>
                                    <Box
                                      component={'button'}
                                      borderRadius={'4px'}
                                      className={'font-white-16-bold'}
                                      marginRight={'50px'}
                                      padding={'15px 20px'}
                                      border={
                                        elem.questionResponse == 2
                                          ? '1px solid rgba(101, 178, 110, 1)'
                                          : '1px solid #F4FAFF'
                                      }
                                      bgcolor={'#101115'}
                                      onClick={() => handleData(index, ind, 2)}
                                    >
                                      2
                                    </Box>
                                  </div>
                                  <div>
                                    <Box
                                      component={'button'}
                                      borderRadius={'4px'}
                                      className={'font-white-16-bold'}
                                      marginRight={'50px'}
                                      padding={'15px 20px'}
                                      border={
                                        elem.questionResponse == 3
                                          ? '1px solid rgba(101, 178, 110, 1)'
                                          : '1px solid #F4FAFF'
                                      }
                                      bgcolor={'#101115'}
                                      onClick={() => handleData(index, ind, 3)}
                                    >
                                      3
                                    </Box>
                                  </div>
                                  <div>
                                    <Box
                                      component={'button'}
                                      borderRadius={'4px'}
                                      className={'font-white-16-bold'}
                                      marginRight={'50px'}
                                      padding={'15px 20px'}
                                      border={
                                        elem.questionResponse == 4
                                          ? '1px solid rgba(101, 178, 110, 1)'
                                          : '1px solid #F4FAFF'
                                      }
                                      bgcolor={'#101115'}
                                      onClick={() => handleData(index, ind, 4)}
                                    >
                                      4
                                    </Box>
                                  </div>
                                  <div>
                                    <Box
                                      component={'button'}
                                      borderRadius={'4px'}
                                      className={'font-white-16-bold'}
                                      marginRight={'50px'}
                                      padding={'15px 20px'}
                                      border={
                                        elem.questionResponse == 5
                                          ? '1px solid rgba(101, 178, 110, 1)'
                                          : '1px solid #F4FAFF'
                                      }
                                      bgcolor={'#101115'}
                                      onClick={() => handleData(index, ind, 5)}
                                    >
                                      5
                                    </Box>
                                  </div>
                                </Box>
                                <div
                                  className={
                                    isMobileScreen
                                      ? 'd-flex justify-content-between w-100'
                                      : 'd-flex justify-content-between w-75'
                                  }
                                >
                                  <main className="font-white-14-normal mt-3">
                                    Extremely Disatisfied
                                  </main>
                                  <main className="font-white-14-normal mt-3">
                                    Extremely Satisfied
                                  </main>
                                </div>
                              </>
                            );
                          } else if (elem.questionType === 'Comment') {
                            return (
                              <TextField
                                fullWidth
                                multiline
                                rows={5}
                                placeholder={elem.questionLabel}
                                variant="outlined"
                                type="text"
                                InputProps={{ className: classes.input }}
                                value={elem.questionResponse}
                                onChange={(e) => {
                                  handleData(index, ind, e.target.value);
                                }}
                              />
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </Box>
              </div>
              <div
                className={
                  isMobileScreen
                    ? 'mt-5'
                    : 'card-footer mt-2 d-flex justify-content-end gap-3'
                }
              >
                <button
                  className="transparent-button rounded-2 py-1 px-3 mx-1"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                {data?.feedbackScore?.Evaluated__c !== true && (
                  <button
                    className="-contained-button rounded-2 py-1 px-3 float-end"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </Box>
          </Fade>
        )}
      </Popper>
      {/* {tabValue !== 2 && (
        <Box
          component={'button'}
          className="primary-button px-4 py-2 rounded-2 floating-button"
          sx={{ backgroundColor: 'rgba(101, 178, 110, 1)' }}
          onClick={handleClick}
          visibility={open === true ? 'hidden' : 'visible'}
        >
          <BsChat fontWeight={'800'} color="#fff" /> FeedBack
        </Box>
      )} */}
    </>
  );
};

export default StartUps;
