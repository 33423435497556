import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import KenInput from '../../../Components/KenInput';
import KenSelect from '../../../Components/KenSelect';
const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: '400',
    fontSize: '13px',
    padding: '1px 4px 4px',
    borderRadius: '5px',
    fontFamily: 'poppins',
    color: 'rgba(0, 0, 0, 1)',
    textTransform: 'capitalize',
    ['@media (max-width: 800px)']: {
      fontSize: '7px',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '7px',
    },
  },
}));
const AwardsChild = (props) => {
  const classes = useStyles();
  const errorMessage = 'Field is required.';
  const [awardsAchievements, setAwardsAchievements] = useState([]);
  const initialState = {
    name: '',
    nameError: '',
    type: '',
    typeError: '',
    date: '',
    dateError: '',
  };
  const [awardsObject, setAwardsObject] = useState(initialState);
  const [open, setOpen] = React.useState(false);
  const handleOnChange = (label, value, errorLabel) => {
    setAwardsObject({
      ...awardsObject,
      [label]: value,
      [errorLabel]: value == '' ? errorMessage : '',
    });
  };
  const editData = (label, value, errorLabel, index) => {
    const obj = {
      ...awardsAchievements[index],
      [label]: value,
      [errorLabel]: value == '' ? errorMessage : '',
    };
    awardsAchievements[index] = { ...obj };
    setAwardsAchievements([...awardsAchievements]);
  };
  const validateForm = () => {
    if (
      awardsObject.name != '' &&
      awardsObject.type != '' &&
      awardsObject.date != ''
    ) {
      return true;
    } else {
      setAwardsObject({
        ...awardsObject,
        nameError: awardsObject.name == '' ? errorMessage : '',
        typeError: awardsObject.type == '' ? errorMessage : '',
        dateError: awardsObject.date == '' ? errorMessage : '',
      });
      return false;
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClickOpen = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
  };
  const handleDelete = () => {
    awardsAchievements.splice(selectedIndex, 1);
    setAwardsAchievements([...awardsAchievements]);
    handleClose();
  };
  return (
    <Grid container xs={12}>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontSize: '15px' }} id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {awardsAchievements?.length > 0 &&
        awardsAchievements.map((item, index) => {
          return (
            <>
              <Grid item xs={3} style={{ marginRight: 20, padding: '5px' }}>
                <KenInput
                  required
                  name="Name of Recognition"
                  label="Name of Recognition"
                  placeholder="Enter Name of Recognition"
                  labelClassName={classes.inputLabel}
                  onChange={(e) => {
                    editData('name', e.target.value, 'nameError', index);
                  }}
                  error={item.nameError}
                  value={item.name}
                />
              </Grid>
              <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
                <KenInput
                  required
                  name="Type of Recognition"
                  label="Type of Recognition"
                  placeholder="Enter Type"
                  labelClassName={classes.inputLabel}
                  onChange={(e) => {
                    editData('type', e.target.value, 'typeError', index);
                  }}
                  error={item.typeError}
                  value={item.type}
                />
              </Grid>
              <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
                <KenSelect
                  name="program"
                  required
                  label="Date Recieved"
                  placeholder="Select"
                  labelClassName={classes.inputLabel}
                  options={[
                    { label: 'react', value: 1 },
                    { label: 'c++', value: 2 },
                  ]}
                  handleChange={(e) => {
                    editData('date', e.target.value, 'dateError', index);
                  }}
                  error={item.dateError}
                  value={item.date}
                />
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  handleClickOpen(index);
                }}
              >
                <DeleteIcon
                  style={{
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px',
                    marginBottom: '-26px',
                  }}
                />
              </div>
            </>
          );
        })}
      <>
        <Grid item xs={3} style={{ marginRight: 20, padding: '5px' }}>
          <KenInput
            required
            name="Name of Recognition"
            label="Name of Recognition"
            placeholder="Enter Name of Recognition"
            labelClassName={classes.inputLabel}
            onChange={(e) => {
              handleOnChange('name', e.target.value, 'nameError');
            }}
            error={awardsObject.nameError}
            value={awardsObject.name}
          />
        </Grid>
        <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
          <KenInput
            required
            name="Type of Recognition"
            label="Type of Recognition"
            placeholder="Enter Type"
            labelClassName={classes.inputLabel}
            onChange={(e) => {
              handleOnChange('type', e.target.value, 'typeError');
            }}
            error={awardsObject.typeError}
            value={awardsObject.type}
          />
        </Grid>
        <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
          <KenSelect
            name="program"
            required
            label="Date Recieved"
            placeholder="Select"
            labelClassName={classes.inputLabel}
            options={[
              { label: 'react', value: 1 },
              { label: 'c++', value: 2 },
            ]}
            handleChange={(e) => {
              handleOnChange('date', e.target.value, 'dateError');
            }}
            error={awardsObject.dateError}
            value={awardsObject.date}
          />
        </Grid>
      </>
      <Grid item xs={12}>
        <Box>
          <div style={{ display: 'flex' }}>
            <Link
              component="button"
              variant="body2"
              underline="none"
              style={{ marginTop: '10px', color: '#000000', fontSize: '13px' }}
              onClick={() => {
                if (validateForm()) {
                  setAwardsAchievements([
                    ...awardsAchievements,
                    { ...awardsObject },
                  ]);
                  setAwardsObject({ ...initialState });
                }
              }}
            >
              + Add Award/Honor
            </Link>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
export default AwardsChild;
