// Note: this is just temporary theme helper for providing support to different client
export const THEME_NAMES = {
  JDIFT: 'JDIFT',
  MANIPAL: 'MANIPAL',
  KEN42: 'ken42',
  NSOM: 'NSOM',
  VENTURISE: 'VENTURISE',
  STPI: 'STPI'
};
const themeList = Object.values(THEME_NAMES);
let currentTheme = themeList[5]; // set theme setup as per client

// Loads theme from localstorage
const localActiveTheme = localStorage.getItem('activeTheme');
if (localActiveTheme && themeList.includes(localActiveTheme)) {
  currentTheme = localActiveTheme;
}

export const getActiveTheme = () => {
  return currentTheme;
};

export const isActiveTheme = (name) => {
  return name === localActiveTheme;
};

const general = {
  JDIFT: {
    bannerFont: '#0052B4',
    bannerNameFont: '#EFEFEF',
    bannerBG: '#EFEFEF',
    bannerTextShadow: '1.5px 0 0 #0052B4, -1.5px 0 0 #0052B4, 0 1.5px 0 #0052B4, 0 -1.5px 0 #0052B4, 1px 1px #0052B4, -1px -1px 0 #0052B4, 1px -1px 0 #0052B4, -1px 1px 0 #0052B4',
    fieldLabel: '#0052B4',
    sectionTitle: '#0F5BAA',
    landingPageTitle: '#505F79',
    landingPageTitleSide: '#0F5BAA',
    landingPageDate: '#092682',
    landingPageFooter: '#98CA3C',
    eventBannerBG: 'EFEFEF',
    alumniFontColor: '#092682',
    tagBg: 'rgba(0, 82, 180, 0.5)',
    pillPostedBg: '#FDB813',
    pillAppliedBg: '#0052B4',
    knowMoreText: '#FDB813',
    jobSummaryBg: '#0052B4',
    appliedJobBG: '#0052B4 !important',
    salaryFontColor: '#0052B4',
    uploadTextFontColor: '#0052B4',
    uploadBorder: '#0052B4',
    myProfileTitleText: '#00218D',
    myName: '#0052B4',
    progressColor: '#27AE60',
    barColor: '#27AE60',
    eventTitleColor: '#0052B4',
    eventOrganizer: '#5040A1',
    eventLabel: '#FDB813',
  },
  ken42: {
    bannerFont: '#0052B4',
    bannerNameFont: '#EFEFEF',
    bannerBG: '#EFEFEF',
    bannerTextShadow: '1.5px 0 0 #0052B4, -1.5px 0 0 #0052B4, 0 1.5px 0 #0052B4, 0 -1.5px 0 #0052B4, 1px 1px #0052B4, -1px -1px 0 #0052B4, 1px -1px 0 #0052B4, -1px 1px 0 #0052B4',
    fieldLabel: '#0052B4',
    sectionTitle: '#0F5BAA',
    landingPageTitle: '#505F79',
    landingPageTitleSide: '#0F5BAA',
    landingPageDate: '#092682',
    landingPageFooter: '#98CA3C',
    eventBannerBG: 'EFEFEF',
    alumniFontColor: '#092682',
    tagBg: 'rgba(0, 82, 180, 0.5)',
    pillPostedBg: '#FDB813',
    pillAppliedBg: '#0052B4',
    knowMoreText: '#FDB813',
    jobSummaryBg: '#0052B4',
    appliedJobBG: '#0052B4 !important',
    salaryFontColor: '#0052B4',
    uploadTextFontColor: '#0052B4',
    uploadBorder: '#0052B4',
    myProfileTitleText: '#00218D',
    myName: '#0052B4',
    progressColor: '#27AE60',
    barColor: '#27AE60',
    eventTitleColor: '#0052B4',
    eventOrganizer: '#5040A1',
    eventLabel: '#FDB813',
  },
  MANIPAL: {
    bannerFont: '#F25F29',
    bannerNameFont: '#EFEFEF',
    bannerBG: 'rgba(239, 239, 239, 0.29)',
    bannerTextShadow: '1.5px 0 0 #F25F29, -1.5px 0 0 #F25F29, 0 1.5px 0 #F25F29, 0 -1.5px 0 #F25F29, 1px 1px #F25F29, -1px -1px 0 #F25F29, 1px -1px 0 #F25F29, -1px 1px 0 #F25F29',
    fieldLabel: '#FE9F43',
    sectionTitle: '#000000',
    landingPageTitle: '#000000',
    landingPageTitleSide: '#F25F29',
    landingPageDate: '#F25F29',
    landingPageFooter: '#F25F29',
    eventBannerBG: 'rgba(254, 159, 67, 0.16)',
    alumniFontColor: '#000000',
    tagBg: 'rgba(0, 0, 0, 0.5)',
    pillPostedBg: '#F25F29',
    pillAppliedBg: '#000000',
    knowMoreText: '#F25F29',
    jobSummaryBg: '#F25F29',
    appliedJobBG: '#000000 !important',
    salaryFontColor: '#F25F29',
    uploadTextFontColor: '#000000',
    uploadBorder: '#F25F29',
    myProfileTitleText: '#000000',
    myName: '#000000',
    progressColor: '#3C4758',
    barColor: '#27AE60',
    eventTitleColor: '#00000',
    eventOrganizer: '#00000',
    eventLabel: '#000000',
  },
  NSOM: {
    bannerFont: '#2c3691',
    bannerNameFont: '#EFEFEF',
    bannerBG: 'rgba(239, 239, 239, 0.29)',
    bannerTextShadow: '1.5px 0 0 #2c3691, -1.5px 0 0 #2c3691, 0 1.5px 0 #2c3691, 0 -1.5px 0 #2c3691, 1px 1px #2c3691, -1px -1px 0 #2c3691, 1px -1px 0 #2c3691, -1px 1px 0 #2c3691',
    fieldLabel: '#FE9F43',
    sectionTitle: '#000000',
    landingPageTitle: '#000000',
    landingPageTitleSide: '#2c3691',
    landingPageDate: '#2c3691',
    landingPageSpotlightBg: '#E7E625',
    landingPageFooter: '#E1E1E1',
    eventBannerBG: 'rgba(254, 159, 67, 0.16)',
    alumniFontColor: '#000000',
    tagBg: 'rgba(0, 0, 0, 0.5)',
    pillPostedBg: '#2c3691',
    pillAppliedBg: '#000000',
    knowMoreText: '#2c3691',
    jobSummaryBg: '#2c3691',
    appliedJobBG: '#000000 !important',
    salaryFontColor: '#2c3691',
    uploadTextFontColor: '#000000',
    uploadBorder: '#2c3691',
    myProfileTitleText: '#000000',
    myName: '#000000',
    progressColor: '#3C4758',
    barColor: '#27AE60',
    eventTitleColor: '#00000',
    eventOrganizer: '#00000',
    eventLabel: '#000000',
  },
  VENTURISE: {
    bannerFont: '#CF222F',
    bannerNameFont: '#EFEFEF',
    bannerBG: 'rgba(239, 239, 239, 0.29)',
    bannerTextShadow: '1.5px 0 0 #CF222F, -1.5px 0 0 #CF222F, 0 1.5px 0 #CF222F, 0 -1.5px 0 #CF222F, 1px 1px #CF222F, -1px -1px 0 #CF222F, 1px -1px 0 #CF222F, -1px 1px 0 #CF222F',
    fieldLabel: '#FE9F43',
    sectionTitle: '#000000',
    landingPageTitle: '#000000',
    landingPageTitleSide: '#2c3691',
    landingPageDate: '#2c3691',
    landingPageSpotlightBg: '#E7E625',
    landingPageFooter: '#E1E1E1',
    eventBannerBG: 'rgba(254, 159, 67, 0.16)',
    alumniFontColor: '#000000',
    tagBg: 'rgba(0, 0, 0, 0.5)',
    pillPostedBg: '#2c3691',
    pillAppliedBg: '#000000',
    knowMoreText: '#2c3691',
    jobSummaryBg: '#2c3691',
    appliedJobBG: '#000000 !important',
    salaryFontColor: '#2c3691',
    uploadTextFontColor: '#000000',
    uploadBorder: '#2c3691',
    myProfileTitleText: '#000000',
    myName: '#000000',
    progressColor: '#3C4758',
    barColor: '#27AE60',
    eventTitleColor: '#00000',
    eventOrganizer: '#00000',
    eventLabel: '#000000',
  },
  STPI: {
    bannerFont: '#0D67B0',
    bannerNameFont: '#EFEFEF',
    bannerBG: 'rgba(239, 239, 239, 0.29)',
    bannerTextShadow: '1.5px 0 0 #0D67B0, -1.5px 0 0 #0D67B0, 0 1.5px 0 #0D67B0, 0 -1.5px 0 #0D67B0, 1px 1px #0D67B0, -1px -1px 0 #0D67B0, 1px -1px 0 #0D67B0, -1px 1px 0 #0D67B0',
    fieldLabel: '#FE9F43',
    sectionTitle: '#000000',
    landingPageTitle: '#000000',
    landingPageTitleSide: '#2c3691',
    landingPageDate: '#2c3691',
    landingPageSpotlightBg: '#E7E625',
    landingPageFooter: '#E1E1E1',
    eventBannerBG: 'rgba(254, 159, 67, 0.16)',
    alumniFontColor: '#000000',
    tagBg: 'rgba(0, 0, 0, 0.5)',
    pillPostedBg: '#2c3691',
    pillAppliedBg: '#000000',
    knowMoreText: '#2c3691',
    jobSummaryBg: '#2c3691',
    appliedJobBG: '#000000 !important',
    salaryFontColor: '#2c3691',
    uploadTextFontColor: '#000000',
    uploadBorder: '#2c3691',
    myProfileTitleText: '#000000',
    myName: '#000000',
    progressColor: '#3C4758',
    barColor: '#27AE60',
    eventTitleColor: '#00000',
    eventOrganizer: '#00000',
    eventLabel: '#000000',
  }
}

const drawer = {
  JDIFT: {
    background: '#63BCE9',
    sideNavItemActiveBackground: '#63BCE9'
  },
  ken42: {
    background: '#63BCE9',
    sideNavItemActiveBackground: '#63BCE9'
  },
  MANIPAL: {
    background: '#F25F29',
    sideNavItemActiveBackground: '#000000',
  },
  NSOM: {
    background: '#2c3691',
    sideNavItemActiveBackground: '#000000',
  },
  VENTURISE: {
    background: '#CF222F',
    sideNavItemActiveBackground: '#CF222F',
  },
  STPI: {
    background: 'linear-gradient(176.94deg, #081f46 14.53%, #000F1E 95.84%)',
    sideNavItemActiveBackground: 'yellow',
  }
};

const calendar = {
  JDIFT: {
    arrowColor: '#20365C',
    calendarTitle: '#20365C',
    highlightedDayColor: '#158ABB',
    semiHighlightColor: 'white',
    semiHighlight: '#158ABB',
    weekdayColor: '#7A869A',
    disabledColor: '#B3BAC5',
    activeDayColor: '#20365C',
  },
  ken42: {
    arrowColor: '#092682',
    calendarTitle: '#091E42',
    highlightedDayColor: '#092682',
    semiHighlight: '#DFE8FF',
    semiHighlightColor: 'black',
    weekdayColor: '#7A869A',
    disabledColor: '#B3BAC5',
    activeDayColor: '#20365C',
  },
};

const buttonPrimary = {
  JDIFT: {
    textColor: '#FFFFFF !important',
    backgroundColor: '#FDB813 !important',
    disabledTextColor: 'rgba(0, 0, 0, 0.26)',
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.12)',
    disabledBoxShadow: 'none',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    hoverColor: '',
    borderColor: '',
    btnColor: '#20365C',
  },
  MANIPAL: {
    textColor: '#FFFFFF !important',
    backgroundColor: '#F25F29 !important',
    disabledTextColor: 'rgba(0, 0, 0, 0.26)',
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.12)',
    disabledBoxShadow: 'none',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    hoverColor: '',
    borderColor: '',
    btnColor: '#20365C',
  },
  NSOM: {
    textColor: '#FFFFFF !important',
    backgroundColor: '#2c3691 !important',
    disabledTextColor: 'rgba(0, 0, 0, 0.26)',
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.12)',
    disabledBoxShadow: 'none',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    hoverColor: '',
    borderColor: '',
    btnColor: '#20365C',
  },
  ken42: {
    textColor: 'white',
    backgroundColor: '#092682',
    disabledTextColor: 'rgba(0, 0, 0, 0.26)',
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.12)',
    disabledBoxShadow: 'none',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    hoverColor: '',
    borderColor: '',
    btnColor: '#092682',
  },
  VENTURISE: {
    textColor: 'white',
    backgroundColor: '#CF222F',
    disabledTextColor: 'rgba(0, 0, 0, 0.26)',
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.12)',
    disabledBoxShadow: 'none',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    hoverColor: '',
    borderColor: '',
    btnColor: '#CF222F',
  },
  STPI: {
    textColor: 'white',
    backgroundColor: '#0D67B0',
    disabledTextColor: 'rgba(0, 0, 0, 0.26)',
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.12)',
    disabledBoxShadow: 'none',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    hoverColor: '',
    borderColor: '',
    btnColor: '#0D67B0',
  },

};

const componentTheme = {
  JDIFT: {},
  ken42: {},
  MANIPAL: {},
  NSOM: {},
  VENTURISE: {},
  STPI: {}
};

[
  { key: 'calendar', style: calendar },
  { key: 'buttonPrimary', style: buttonPrimary },
  { key: 'drawer', style: drawer },
  { key: 'general', style: general },
].forEach((cTheme) => {
  Object.keys(cTheme.style).forEach((themeKey) => {
    // JDIFT -> calendar = styles
    componentTheme[themeKey][cTheme.key] = cTheme.style[themeKey];
  });
});

export const activeTheme = componentTheme[currentTheme];
