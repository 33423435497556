import { AccountLink, SubTitle, Title } from '../common';
import { Box, CircularProgress, FormControl } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { requestForOTP, signUp } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';

import DatePicker from 'react-date-picker';
import KenButton from '../../../Components/KenButton';
import KenInput from '../../../Components/KenInput';
import KenPhoneInput from '../../../Components/KenPhoneInput';
import KenSelect from '../../../Components/KenSelect';
import KenTextLabel from '../../../Components/KenTextLabel';
import { LOGIN_BG } from '../Login';
import MobileLoginBG from '../../../Assets/Svg/login/login-mobile-bg.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { activeTheme } from '../../../Utils/Themes/themeHelper';
import calendarIcon from '../../../Assets/Svg/calendar.svg';
import { getAcademicPrograms } from '../../../Utils/apiServices';
import { makeStyles } from '@mui/styles';
import routes from '../../../Constants/routes';
import { useSnackbar } from 'notistack';
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
  customDatePicker: {
    '& .react-date-picker__calendar': {
      zIndex: 2,
    },
    '& .react-date-picker__wrapper': {
      border: 0,
      borderRadius: '4px',
      padding: '6px 16px',
      backgroundColor: '#F3F5F7',
      textAlign: 'left',
      '&:focus': {
        backgroundColor: theme.palette.KenColors.kenWhite,
      },
      '& input': {
        color: theme.palette.KenColors.grey2,
      },
      '& .react-date-picker__inputGroup__divider': {
        color: theme.palette.KenColors.grey2,
      },
      '& .react-date-picker__button': {
        paddingRight: 0,
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundImage: `url(${LOGIN_BG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ['@media (max-width: 600px)']: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '100%',
      margin: '10px',
      background: 'none !important',
    },
    ['@media (max-width: 320px)']: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '100%',
      margin: '10px',
      background: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },

    '& form': {
      top: () => (1 / window.devicePixelRatio === 1 ? '50%' : 0),
      transform: () =>
        1 / window.devicePixelRatio === 1 ? 'translateY(-50%)' : 0,
      position: () => (1 / window.devicePixelRatio === 1 ? 'relative' : 0),
    },
  },
  loginContent: {
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
    '& .scrollbar-container': {
      maxWidth: '500px',
      minHeight: '520px',
      padding: '48px 84px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '360px',
      },
      [theme.breakpoints.up('sm')]: {
        maxHeight: '850px',
        overflowY: 'auto',
      },
    },
  },
  title: {
    textAlign: 'left',
    marginBottom: '16px',
    ['@media (max-width: 320px)']: {
      textAlign: 'center',
      marginLeft: '40px',
    marginBottom: '16px',
    }
  },
  subTitle: {
    marginBottom: '32px',
    ['@media (max-width: 320px)']: {
      marginLeft: '10px',
      marginBottom: '38px',
    }
  },
  inputFieldLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: theme.palette.KenColors.grey2,
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
  signupWith: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.KenColors.neutral100,
  },
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
  },
  phoneContainer: {
    display: 'flex',
    maxWidth: '270px !important',
    '& .phone-input': {
      fontFamily: 'Poppins,sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '100%',
      color: theme.palette.KenColors.grey2,
      padding: '16px',
      order: 2,
      background: '#F3F5F7',
      border: 0,
      height: '48px',
      borderRadius: '4px',
      marginLeft: '11px',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '100%',
        color: '#A8AFBC',
        fontFamily: 'Poppins,sans-serif',
      },
    },
    '& .phone-dropdown': {
      position: 'relative',
      order: 1,
      border: 0,
      background: '#F3F5F7',
      '&.open': {
        background: '#F3F5F7',
        '& .selected-flag': {
          background: '#F3F5F7',
        },
      },
      '& .selected-flag': {
        width: '50px',
        padding: '10px',
        '&:hover': {
          background: '#F3F5F7',
        },
      },
    },
  },
  selectBox: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100 %",
    textTransform: "capitalize"
  }
}));

const email = (value) => {
  return validator.isEmail(value) ? undefined : 'Enter valid email';
};
const dateValidator = (value) => {
  return validator.isDate(value) ? undefined : 'Enter valid date';
};

const minimum = (minimum) => (value) => {
  return value?.length < minimum
    ? `Enter at least ${minimum} characters`
    : undefined;
};

const errorMapper = (error) => {
  // console.log(error);
  if (!error) {
    return undefined;
  }

  if (error?.message) {
    return error?.message;
  }

  if (error?.type) {
    switch (error?.type) {
      case 'required':
        return 'Required';
      case 'min':
        return 'Enter minimum characters';
      case 'max':
        return 'Added more than allowed characters';
      default:
    }
  }

  return 'Error';
};

const phoneValidator = (value) => {
  if (validator.isMobilePhone(value, 'en-IN', { strictMode: true })) {
    return true;
  }

  return 'Please enter valid phone';
};

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [date, onDateChange] = useState(null);
  const [allPrograms, setProgramList] = useState(null);
  const { state: navigateState } = useLocation();
  const [getNavigate, setNavigate] = useState(navigateState);

  useEffect(() => {
    console.log(navigateState);
    setLoading(true);
    getAcademicPrograms()
      .then((res) => {
        if (res?.data?.length > 0) {
          const programs = [{ label: "Select", value: null }];
          res.data.forEach((el, index) => {
            programs.push({ label: el?.accountName, value: el?.recordId });
          });
          setProgramList(programs);
          setLoading(false);
        } else {
          setProgramList([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const { control, handleSubmit, setValue, trigger } = useForm({
    defaultValues: {
      firstName: navigateState?.firstName,
      middleName: '',
      lastName: navigateState?.lastName,
      email: navigateState?.email,
      phone: '',
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await signUp(data);

      if (!res || !res?.success) {
        console.log('inside');
        console.log(Array.isArray(res?.errorMessage));
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      if (!res?.success) {
        if (res.errorMessage) {
          alert(res?.errorMessage || 'Something went wrong');
        }
      }

      // TODO: Change it back to verifyLogin and id to phone
      await requestForOTP({ id: data.email });

      if (!res?.success) {
        if (res.errorMessage) {
          console.log('inside');
          alert(
            res?.errorMessage
              ? Array.isArray(res?.errorMessage) &&
              res?.errorMessage[0]?.errorMessage
              : res?.errorMessage || 'Something went wrong'
          );
        }
      }
      console.log(res);
      navigate(routes.verifyEmail, {
        state: {
          id: res.data.alumni.recordId,
          email: data.email,
          phone: data.phone,
        },
      });

    } catch (error) {
      return enqueueSnackbar(
        error && error?.response?.data
          ? Array.isArray(error?.response?.data?.errorMessages) &&
          error?.response?.data?.errorMessages[0]?.errorMessage
          : error?.response?.data?.errorMessage || 'Something went wrong',
        {
          variant: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };

  // const body = (
  //   <div style={modalStyle} className={classes.paper}>
  //     {/* Modal */}
  //   </div>
  // );

  return (
    <>
      <Box className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.loginContent}>
            <PerfectScrollbar>
              <Title className={classes.title} text="Get Started!" />
              <SubTitle
                className={classes.subTitle}
                text="One Step Closer to Reconnecting"
              />
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true, validate: minimum(2) }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        required
                        name="firstName"
                        label="First Name"
                        placeholder="Enter First Name"
                        errorLabel={false}
                        labelClassName={classes.inputFieldLabel}
                        {...field}
                        error={errorMapper(error)}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        name="middleName"
                        label="Middle Name"
                        placeholder="Enter Middle Name"
                        labelClassName={classes.inputFieldLabel}
                        errorLabel={false}
                        {...field}
                        error={errorMapper(error)}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true, validate: minimum(2) }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        required
                        name="lastName"
                        label="Last Name"
                        placeholder="Enter Last Name"
                        errorLabel={false}
                        labelClassName={classes.inputFieldLabel}
                        {...field}
                        error={errorMapper(error)}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, validate: email }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenInput
                        required
                        name="email"
                        label="Email"
                        placeholder="Enter Email"
                        labelClassName={classes.inputFieldLabel}
                        errorLabel={false}
                        {...field}
                        error={errorMapper(error)}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true, validate: phoneValidator }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenPhoneInput
                        name="phone"
                        style={{ maxwidth: '270px' }}
                        containerClass={classes.phoneContainer}
                        inputClass={`${classes.inputFieldLabel} phone-input ${classes.phoneWidth}`}
                        buttonClass="phone-dropdown"
                        country={'in'}
                        placeholder="Enter Number"
                        errorLabel={false}
                        {...field}
                        error={errorMapper(error)}
                        onChange={(phone) => {
                          setValue('phone', phone);
                          trigger('phone');
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true, validate: dateValidator }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <>
                        <KenTextLabel
                          label="Year of graduation"
                          labelClassName={classes.inputFieldLabel}
                          required={true}
                        />
                        <DatePicker
                          name="date"
                          className={classes.customDatePicker}
                          clearIcon={null}
                          format="y"
                          maxDetail="decade"
                          yearPlaceholder="----"
                          {...field}
                          errorLabel={false}
                          error={errorMapper(error)}
                          onChange={(date) => {
                            setValue('date', date);
                            onDateChange(date);
                          }}
                          value={date}
                          calendarIcon={
                            <img src={calendarIcon} alt="calender" />
                          }
                        />
                      </>
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: '24px' }}>
                <Controller
                  name="program"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KenSelect
                        name="program"
                        required
                        select
                        label="Program Name"
                        labelClassName={classes.selectBox}
                        options={allPrograms}
                        value={allPrograms}
                        errorLabel={false}
                        {...field}
                        error={errorMapper(error)}
                      />
                    );
                  }}
                />
              </FormControl>
              <Box width="100%">
                <KenButton
                  variant="primary"
                  buttonClass={classes.verify}
                  type="submit"
                  disabled={loading}
                >
                  Verify
                </KenButton>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '49%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                )}
              </Box>
              <Box
                display={'flex'}
                justifyContent="center"
                alignItems={'center'}
                width="100%"
              >
                <AccountLink
                  text="Already Have An Account?"
                  actionText={'Log In'}
                  onAction={() => navigate(routes.login)}
                />
              </Box>
            </PerfectScrollbar>
          </Box>
        </form>
      </Box>
    </>
  );
}
