import React, { useState } from 'react'
import Educationdetails from './EducationDetails';
import Personaldetailschild from './PersonalDetailschild';
import Workexperience from './WorkExperience';
import KenAccordion from '../../../Components/KenAccordion';





export const PersonalDetails = () => {
  const PersonalDetailsData = [
    {
      id: 1,
      label: 'Personal Details',
      component: <Personaldetailschild />,
      expanded: true,
    },
    {
      id: 2,
      label: 'Education Details',
      component: <Educationdetails />,
      expanded: true,
    },
    {
      id: 3,
      label: 'Work Experience',
      component: <Workexperience />,
      expanded: true,
    }
  ];
  const [personaldetailsArray, setPersonaldetailsArray] = useState(PersonalDetailsData)
  return (
    <div>
      {personaldetailsArray.map((item, index) => {
        return (
          <KenAccordion
            style={{ minHeight: '30px' }}
            label={item.label}
            children={item.component}
            expanded={item.expanded}
            key={index}
            handleChange={() => {
              personaldetailsArray[index].expanded =
                !personaldetailsArray[index].expanded;
              setPersonaldetailsArray([...personaldetailsArray])
            }}
          />
        );
      })}
    </div>
  );
};
