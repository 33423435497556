import * as React from 'react';

import { Box, Divider, Typography } from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '../../Assets/Icons/error.png';
import KenIcon from '../KenIcon';
import KenTextField from '../KenTextField';
import KenTextLabel from '../KenTextLabel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  errorStyles: {
    backgroundColor: '#ffffff',
  },
  successMessage: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#158467',
  },
});

export default function KenInput(props) {
  const {
    label,
    required,
    optionalLabel,
    error,
    ui,
    success,
    hideLabel = false,
    ...otherProps
  } = props;

  const classes = useStyles();

  return (
    <>
      <Box width="100%">
        {!hideLabel && (
          <KenTextLabel
            label={label}
            required={required}
            optionalLabel={optionalLabel}
            labelClassName={props.labelClassName}
          />
        )}

        <Box display="flex" alignItems="flex-start">
          <KenTextField error={error} {...otherProps} label="" />
          {error && (
            <Box width="16px" height="16px" margin="19px 8px">
              <KenIcon
                iconType="img"
                icon={ErrorIcon}
                variant="extraSmall"
              ></KenIcon>
            </Box>
          )}
        </Box>
        {success && (
          <Box display="flex" alignItems="flex-start" pt={1}>
            <Typography component="span">
              {success.icon || (
                <DoneIcon fontSize="small" style={{ color: '#158467' }} />
              )}
            </Typography>
            <Typography
              component="span"
              className={classes.successMessage}
              pl={1}
            >
              {success.message}
            </Typography>
          </Box>
        )}
      </Box>
      {ui && ui?.divider && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
    </>
  );
}
