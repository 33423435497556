import { Box, Typography } from '@mui/material';

import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  THEME_NAMES,
  activeTheme,
  getActiveTheme,
  isActiveTheme,
} from '../../../Utils/Themes/themeHelper';
const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '100%',
    color: theme.palette.KenColors.neutral900,
  },

  subTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.KenColors.neutral400,
  },
  link: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '100%',
    color: activeTheme.general.alumniFontColor,
    cursor: 'pointer',
  },
  actionLink: {
    fontWeight: '600',
    cursor: 'pointer',
    marginLeft: '5px',
    color: activeTheme.general.alumniFontColor,
  },
}));

export function Title({ text, className }) {
  const classes = useStyles();

  return (
    <Typography className={`${classes.title} ${className ? className : ''}`}>
      {text}
    </Typography>
  );
}

export function SubTitle({ text, className }) {
  const classes = useStyles();

  return (
    <Typography className={`${classes.subTitle} ${className ? className : ''}`}>
      {text}
    </Typography>
  );
}

export function AccountLink({ text, actionText, onAction }) {
  const classes = useStyles();

  return (
    <Box display={'flex'} alignItems="center">
      <Typography className={classes.link}>{text}</Typography>
      <Typography className={classes.actionLink} onClick={onAction}>
        {actionText}
      </Typography>
    </Box>
  );
}
