import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { THEME_NAMES, getActiveTheme } from '../../Utils/Themes/themeHelper';
import { getAxiosInstance, getConfig } from '../../Utils/apiServices';

import { useTheme } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import KenLoader from '../../Components/KenLoader';
import KenPrivateRoute from '../../Components/KenPrivateRoute';
import MenuAppBar from '../../Components/MenuAppBar';
import SideNavigation from '../../Components/SideNavigation';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import routes from '../../Constants/routes';
import { useAppContext } from '../../Context/appContext';
import { AuthContext } from '../../Context/authContext';
import Login from '../Auth/Login';
import LoginOTP from '../Auth/Login/LoginOTP';
import SignUp from '../Auth/SignUp';
import VerifiedOTP from '../Auth/SignUp/VerifiedOTP';
import VerifyEmail from '../Auth/SignUp/VerifyEmail';
import VerifyNumber from '../Auth/SignUp/VerifyNumber';
import DashBoard from '../Dashboard';
import EventDetail from '../EventDetail';
import EventDetails from '../EventDetail/index.jsx';
import Events from '../Events';
import JobDetail from '../JobDetail';
import Jobs from '../Jobs';
import ProfilePage from '../ProfilePage/index.jsx';
import StartUps from '../Startup';

export default function MainLayout() {
  const [open, setOpen] = React.useState(true);
  const [loadingConfig, setLoadingConfig] = React.useState(true);
  const {
    dispatch: dispatchApp,
    state: { isAuthenticated },
  } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const { state, dispatch } = useAppContext();

  const [config, setConfig] = useState(null);
  const [swipeableDrawer, setSwipeableDrawer] = useState();

  // const [idle, setIdle] = useState(false);
  // const handleOnIdle = () => {
  //   setIdle(true);
  // };
  // const timeout = 1000 * 60 * 10;

  // useIdleTimer({
  //   timeout,
  //   onIdle: handleOnIdle,
  // });

  useEffect(() => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    let activeDomain = 'venturise';
    switch (getActiveTheme()) {
      case THEME_NAMES.JDIFT:
        activeDomain = 'jdift';
        break;
      case THEME_NAMES.MANIPAL:
        activeDomain = 'manipal';
        break;
      case THEME_NAMES.NSOM:
        activeDomain = 'nsom';
        break;
      case THEME_NAMES.KEN42:
        activeDomain = 'jdift';
        break;
      case THEME_NAMES.VENTURISE:
        activeDomain = 'venturise';
        break;
      case THEME_NAMES.STPI:
        activeDomain = 'stpi';
        break;
      default:
        activeDomain = 'jdift';
    }

    link.href = `/landingPageSvg/${activeDomain}/fav.ico`;
  }, []);

  // useEffect(() => {
  //   if (idle === true) {
  //     dispatchApp({
  //       type: 'LOGOUT',
  //       payload: {
  //         user: {},
  //         token: null,
  //       },
  //     });
  //   }
  // }, [dispatchApp, idle]);

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        console.log(res);
        dispatch({ type: 'updateConfig', value: res[0]?.config });
        getAxiosInstance(res);
        setConfig(res[0]?.config);
        // setLoadingConfig(false);
      })
      .catch((err) => {
        console.log('error in config', err);
      });
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const conditionalRender = (Component, props = {}) => {
    return isAuthenticated ? (
      <Navigate to={routes.home} />
    ) : (
      <Component {...props} />
    );
  };

  const Private = (Component, props) => {
    return (
      <KenPrivateRoute>
        <Component {...props} />
      </KenPrivateRoute>
    );
  };

  return (
    <Suspense fallback={<div></div>}>
      <>
        {/* <Helmet
          bodyAttributes={{
            style: isAuthenticated
              ? 'background-color : #F7F8F8'
              : 'background-color : #fff',
          }}
        /> */}
        <div>
          <SnackbarProvider autoHideDuration={5000}>
            <Box
              sx={{
                display: 'flex',
                // height: '100%',
                background: isAuthenticated
                  ? theme.palette.KenColors.background
                  : theme.palette.KenColors.white,
              }}
            >
              <CssBaseline />
              {!config ? (<KenLoader />) : (
                <>
                  {isAuthenticated && (
                    <>
                      {/* <SideNavigation
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                        swipeableDrawer={swipeableDrawer}
                        setOpen={setOpen}
                      />
                      <MenuAppBar
                        open={open}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                      /> */}
                    </>
                  )}
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      // padding: isAuthenticated ? '100px 30px 0 40px' : undefined,
                      // height: !isAuthenticated ? '100%' : 'calc(100vh - 60px)',
                      // width: isMobileScreen ? '100%' : undefined,
                      boxSizing: 'border-box',
                      // marginTop: '64px',
                      // padding: '25px 25px 10px 25px',
                      maxWidth: '100%'
                      //  Note: added following so for main screen the gird is splitted on 12 grid after subtracting side navbar
                      // maxWidth: !isMobileScreen && isAuthenticated ? open ? 'calc(100% - 240px)' : 'calc(100% - 120px)' : undefined,
                    }}
                  >
                    <Routes>
                      <Route path="/" element={<Navigate to={routes.dashboard} />} />
                      <Route
                        path={routes.login}
                        element={isAuthenticated ? <Navigate to="/login" /> : <Login />}
                      />
                      <Route
                        path={routes.login}
                        element={conditionalRender(Login)}
                      />
                      <Route
                        path={routes.signup}
                        element={conditionalRender(SignUp)}
                      />
                      <Route
                        path={routes.loginOTP}
                        element={conditionalRender(LoginOTP)}
                      />
                      <Route
                        path={routes.verifyNumber}
                        element={conditionalRender(VerifyNumber)}
                      />
                      <Route
                        path={routes.verifyEmail}
                        element={conditionalRender(VerifyEmail)}
                      />
                      {/* <Route
                      path={routes.alumniOfMonthProfile}
                      element={Private(AlumniOfMonthProfile)}
                    /> */}
                      <Route
                        path={routes.eventDetails}
                        element={Private(EventDetails)}
                      />
                      <Route
                        path={routes.alumniProfile}
                        element={Private(ProfilePage)}
                      />
                      <Route
                        path={routes.dashboard}
                        element={Private(DashBoard)}
                      />
                      <Route
                        path={routes.startup}
                        element={Private(StartUps)}
                      />
                      <Route
                        path={routes.verifiedOTP}
                        element={conditionalRender(VerifiedOTP)}
                      />
                      <Route
                        exact
                        path={`/dashboard`}
                        redirectTo="/login"
                        element={Private(DashBoard)}
                      // element={Private(HomePage)}
                      />
                      <Route path={routes.jobs} element={Private(Jobs)} />
                      <Route
                        path={routes.jobDetail}
                        element={Private(JobDetail)}
                      />
                      <Route path={routes.events} element={Private(Events)} />
                      <Route path={routes.home} element={Private(DashBoard)} />

                      <Route
                        path={routes.eventDetail}
                        element={Private(EventDetail)}
                      />
                      <Route
                        exact
                        path={`*`}
                        element={<Navigate to={routes.login} />}
                      />
                    </Routes>
                  </Box>
                </>
              )}
            </Box>
          </SnackbarProvider>
        </div>

      </>
    </Suspense>
  );
}
