// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  FacebookAuthProvider
} from "firebase/auth";

// Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCduH9-qLe3Pl5LuqfRJGV2XAU27oYf880",
//   authDomain: "ken42-firebase-auth.firebaseapp.com",
//   projectId: "ken42-firebase-auth",
//   storageBucket: "ken42-firebase-auth.appspot.com",
//   messagingSenderId: "242461347015",
//   appId: "1:242461347015:web:1c8a93a6fc4675d1b202e2",
//   measurementId: "G-FYK8YKSMHJ",
// };

// JDIFT
const firebaseConfig = {
  apiKey: "AIzaSyCVJqNxlzg_2xzZc6OSFADBqSW8sSbrb88",
  authDomain: "jdift-af048.firebaseapp.com",
  projectId: "jdift-af048",
  storageBucket: "jdift-af048.appspot.com",
  messagingSenderId: "584391033856",
  appId: "1:584391033856:web:76bcc203fe1dc83b1644e5",
  measurementId: "G-Z1YV5R20RR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export const signInWithGoogle = async () => {
  try {
    const googleProvider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, googleProvider);
    console.log(res);
    return res;
  } catch (error) {
    console.log("[signInWithGoogle] error: ", error);
    throw error;
  }
};

export const signInWithGithub = async () => {
  try {
    const githubProvider = new GithubAuthProvider();
    const res = await signInWithPopup(auth, githubProvider);
    console.log(res);
    return res;
  } catch (error) {
    console.log("[signInWithGithub] error: ", error);
    throw error;
  }
};

export const signInWithTwitter = async () => {
  try {
    const twitterProvider = new TwitterAuthProvider();
    const res = await signInWithPopup(auth, twitterProvider);
    console.log(res);
    return res;
  } catch (error) {
    console.log("[signInWithTwitter] error: ", error);
    throw error;
  }
};

export const signInWithMicrosoft = async () => {
  try {
    const microsoftProvider = new OAuthProvider('microsoft.com');
    // microsoftProvider.setCustomParameters({
    //   //tenant: '39cfba26-0bba-4dd3-ace7-bb1152000ab4',
    //   // Target specific email with login hint.
    //   login_hint: 'user@ken42.com'
    // });
    const res = await signInWithPopup(auth, microsoftProvider);
    console.log(res);
    return res;
  } catch (error) {
    console.log("[signInWithMicrosoft] error: ", error);
    throw error;
  }
};

export const signInWithFacebook = async () => {
    try {
      const facebookProvider = new FacebookAuthProvider();
      const res = await signInWithPopup(auth, facebookProvider);
      console.log(res);
      return res;
    } catch (error) {
      console.log("[signInWithFacebook] error: ", error);
      throw error;
    }
};