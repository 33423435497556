import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadBlack from '../../Assets/Svg/job/upload-black.svg';
import Upload from '../../Assets/Svg/job/upload.svg';
import KenButton from '../../Components/KenButton';
import { AuthContext } from "../../Context/authContext";
import { createDocuments } from "../../Utils/apiServices";
import { activeTheme, getActiveTheme } from '../../Utils/Themes/themeHelper';
import KenLoader from '../../Components/KenLoader';

const UploadLogo = {
  JDIFT: Upload,
  ken42: Upload,
  MANIPAL: UploadBlack,
  NSOM: Upload,
}[getActiveTheme()];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '452px',
    height: '284px',
    background: '#FFFFFF',
    'border-radius': '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadText: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
    color: activeTheme.general.uploadTextFontColor,
  },
  uploadingMsg: {
    marginTop: '29px'
  },
  apply: {
    width: 156,
    height: '43px',
    marginTop: '29px',
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-weight': '500 !important',
    'font-size': '16px !important',
    'line-height': '24px !important',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
  upload: {
    '& .upload-btn-wrapper': {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'center',
      padding: '13px',
      width: '247px',
      background: '#FFFFFF',
      border: `1px solid ${activeTheme.general.uploadBorder}`,
      'border-radius': '13px',
      marginTop: theme.spacing(3),
      cursor: 'pointer',
    },

    '& .failedBorder': {
      border: '1px solid #BF360C',
    },

    '& .btn': {
      marginTop: theme.spacing(1.5),
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '12px',
      'line-height': '100%',
      'text-align': 'center',
      color: '#A8AFBC',
      background: 'none',
      border: '0',
    },

    '& .failed': {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '12px',
      'line-height': '100%',
      'text-align': 'center',
      color: '#BF360C',
    },

    '& .upload-btn-wrapper input[type=file]': {
      'font-size': '100px',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: '0',
    },
  },
}));



export default function UploadModal({ handleClose, props, handleJob }) {
  const classes = useStyles();
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadData, setUploadData] = useState();
  const [showApplyBtn, setApplyBtn] = useState(false);
  const [showUploadDisplay, setUploadDisplay] = useState(true);
  const [showUploading, setUploadResume] = useState(false);
  const [getRecordedId, setRecordedId] = useState(props.recordId);
  const {
    state: { user },
  } = useContext(AuthContext);

  const onDrop = useCallback(async (acceptedFiles, getInputProps, props) => {
    // setUploadResume(true);
    setUploadDisplay(false);
    setApplyBtn(true);
    // setUploadDisplay(false);
    setUploadResume(false);
    /*console.log(getRecordedId);
    console.log(props);
    setUploadResume(true);
    setUploadDisplay(false);
    const formData = new FormData();
    const [file] = acceptedFiles;
    let base64;
    const pdf = acceptedFiles.find(f => f)
    let reader = new FileReader()
    reader.readAsDataURL(pdf);
    reader.onload = () => {
      base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      const myArray = file.name.split(".");
      const body = {
        fileName: file.name,
        title: myArray[0],
        formattedDocumentName: `${myArray[0]}_${user?.alumniName}.${myArray[myArray.length - 1]}`,
        expiryDate: null,
        body: base64,
        parentId: getRecordedId,
        fileType: file.type,
      };
      createDocuments({ attachments: [body] })
        .then((res) => {
          if (res?.success === true) {
            setApplyBtn(true);
            // setUploadDisplay(false);
            setUploadResume(false);
            // return enqueueSnackbar("Resume Uploaded Successfully", {
            //   variant: "success",
            // });
          }
        })
        .catch((err) => {
          return enqueueSnackbar(
            "Error while uploading. Please try again later.",
            {
              variant: "error",
            }
          );
        });
    }*/
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop
  });

  const applyToJob = async (row, acceptedFiles) => {
    setUploadResume(false);
    setApplyBtn(true);
    // try {
    const [file] = acceptedFiles;
    let base64;
    const pdf = acceptedFiles.find(f => f)
    let reader = new FileReader()
    reader.readAsDataURL(pdf);
    reader.onload = () => {
      base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      const myArray = file.name.split(".");
      const body = {
        fileName: file.name,
        title: myArray[0],
        formattedDocumentName: `${myArray[0]}_${user?.alumniName}.${myArray[myArray.length - 1]}`,
        expiryDate: null,
        body: base64,
        // parentId: getRecordedId,
        fileType: file.type,
      };
      createDocuments({
        alumniAppliedJobs: [
          {
            alumniId: user.id,
            jobListing: row.recordId,
            attachments: [body]
          }]
        ,
      })
        .then((res) => {
          if (res?.success === true) {
            handleClose();
            handleJob();
            return enqueueSnackbar("Applied Successfully", {
              variant: "success",
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            });
          }
        });
    }
    // } catch (error) {
    //   return enqueueSnackbar("Error while uploading. Please try again later.", { variant: "error" }
    //   );
    // } finally {
    //   setLoading(false);
    // }

  }

  const { enqueueSnackbar } = useSnackbar();

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path}
      {/* - {file.size} bytes */}
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <>
      {/* <li key={file.path}>
        {file.path}
        - {file.size} bytes
      </li> */}
      {errors && <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>}
    </>
  ));


  return (
    <>
      {loading && <KenLoader />}
      <Dialog onClose={handleClose} open={true}>
        <Box className={classes.root}>
          {showUploadDisplay && <>
            <Typography className={classes.uploadText}>
              Upload Your Resume
            </Typography>

            <Box className={classes.upload}>
              <section class={`upload-btn-wrapper ${failed ? 'failedBorder' : ''}`}>
                <div {...getRootProps({ className: 'dropzone' })}>

                  <img src={UploadLogo} alt="" height={24} width={24} />

                  <button class={`btn ${failed ? 'failed' : ''}`}>
                    {failed ? 'Upload Failed' : 'PDF Only'}
                  </button>
                  <input {...getInputProps()} />
                  {/* <em>(Only *.jpeg and *.png images will be accepted)</em> */}
                </div>

                {/* <aside> */}
                {/* <h4>Accepted files</h4>
              <ul>{acceptedFileItems}</ul> */}
                {/* <h4>Rejected files</h4>
              <ul>{fileRejectionItems}</ul>
            </aside> */}
              </section>
            </Box>
            {/*acceptedFileItems && <ul>{acceptedFileItems}</ul>*/}
            {fileRejectionItems && <ul>{fileRejectionItems}</ul>}
            {/* <Box className={classes.upload}>
          <div class={`upload-btn-wrapper ${failed ? 'failedBorder' : ''}`}>
            <img src={UploadLogo} alt="" height={24} width={24} />
            <button class={`btn ${failed ? 'failed' : ''}`}>
              {failed ? 'Upload Failed' : 'PDF Only'}
            </button>
            <input type="file" name="myfile" />
          </div>
        </Box> */}
          </>
          }
          {showUploading &&
            <Typography class={classes.uploadingMsg}>
              Resume Uploading....
            </Typography>
          }
          {showApplyBtn &&
            <><Typography className={classes.uploadText}>
              Resume Added
            </Typography>
              <KenButton variant="primary" buttonClass={classes.apply} type="submit"
                // onClick={applyToJob({ props })}
                onClick={() => applyToJob(props, acceptedFiles)}
              >
                Apply
              </KenButton>
            </>
          }

        </Box>
      </Dialog>
    </>
  );
}
