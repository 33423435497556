import { Grid, Typography } from '@mui/material';
import React, { useState } from "react";
import KenSelect from '../../../Components/KenSelect';
import KenTextField from "../../../Components/KenTextField";

import {
  TextField
} from '@mui/material';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './PersonalDetailschild.css';

const Personaldetailschild = (props) => {
  const initialValues = { firstname: "", middlename: "", lastname: "", gender: "", mobilenumber: "", date: "", email: "" };
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  }
  const [date, setDate] = React.useState(null);
  const handleChangeDate = (newValue) => {
    setDate(newValue);
    console.log(date);
  };
  const gender = [{
    value: "Male",
    label: 'Male'
  },
  {
    value: "Female",
    label: 'Female'
  }]

  return (
    <div>
      <form>
        <Grid container xs={12}>
          <Grid xs={2.5} >
            <Typography className="Contentcss">First Name<span style={{ color: "red" }}>*</span></Typography>
            <KenTextField
              required
              className="Input-css"
              placeholder="Enter your First Name"
              fontSize='10px'
              name="firstname"
              type="text"
              value={formValues.firstname}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px' }}>
            <Typography className="Contentcss">Middle Name</Typography>
            <KenTextField
              required
              placeholder="Enter your Middle Name"
              name="middlename"
              type="text"
              value={formValues.middlename}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px' }}>
            <Typography className="Contentcss">Last Name<span style={{ color: "red" }}>*</span></Typography>
            <KenTextField
              required
              placeholder="Enter your Last Name"
              name="lastname"
              type="text"
              value={formValues.lastname}
              onChange={handleChange}

            />
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px' }}>
            <Typography className="Contentcss">Gender<span style={{ color: "red" }}>*</span></Typography>
            <KenSelect
              required
              name="gender"
              options={gender}
              value={formValues.gender}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ marginTop: '10px' }}>
          <Grid xs={2.5}>
            <Typography style={{ marginLeft: '10px' }} className="Contentcss">Mobile Number<span style={{ color: "red" }}>*</span></Typography>
            <KenTextField
              required
              placeholder="Enter Mobile Number"
              name="mobilenumber"
              type="tel"
              value={formValues.mobilenumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px', ['@media (max-width: 800px)']: {
      marginTop:'15px'
    }, }}>
            <Typography style={{ marginLeft: '10px' }} className="Contentcss">Date of Birth<span style={{ color: "red" }}>*</span></Typography>
            <Paper
              component="form"
              sx={{
                boxShadow: 'none',
                width: '100%',
                backgroundColor: '#F3F5F7',
                height: '40px',
              }
              }
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack className="dateRem" spacing={3}>
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    name="dateofbirth"
                    value={date}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Paper>
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px' }}>
            <Typography style={{ marginLeft: '10px', ['@media (max-width: 800px)']: {
      marginBottom:'15px !important'
    },}} className="Contentcss">Email<span style={{ color: "red" }}>*</span></Typography>
            <KenTextField
              required
              placeholder="Enter your Email"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleChange}

            />
          </Grid>
        </Grid>
      </form>
    </div >
  )

}
export default Personaldetailschild