import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Facebook from '../../../src/Assets/Svg/facebook.svg';
import Google from '../../../src/Assets/Svg/google.svg';
import { AuthContext } from '../../Context/authContext';
import { requestForOTP, signUp } from '../Auth/api';
import routes from '../../Constants/routes';

import {
  signInWithFacebook,
  signInWithGithub,
  signInWithGoogle,
  signInWithMicrosoft,
  signInWithTwitter
} from "./firebase";

const useStyles = makeStyles((theme) => ({
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
    '& img.google': {
      width: '37px',
      height: '40px',
    },
  },
}));

export default function SocialIcon() {
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state: navigateState } = useLocation();

  const doGoogleSignin = () => {
    signInWithGoogle()
      .then((res) => {
        const googleUser = res?.user;
        // setUser({
        //   firstName: googleUser?.displayName?.split(" ")[0] || "",
        //   lastName: googleUser?.displayName?.split(" ")[1] || "",
        //   displayName: googleUser?.displayName,
        //   email: googleUser?.email,
        //   phoneNumber: googleUser?.phoneNumber,
        //   photoURL: googleUser?.photoURL,
        // });
        navigate(routes.signup, {
          state: {
            firstName: googleUser?.displayName?.split(" ")[0] || "",
            lastName: googleUser?.displayName?.split(" ")[1] || "",
            email: googleUser?.email,
          },
        });
        /*try {
          const res = signUp({
            firstName: googleUser?.displayName?.split(" ")[0] || "",
            lastName: googleUser?.displayName?.split(" ")[1] || "",
            email: googleUser?.email,
            phone: googleUser?.phoneNumber
          });

          if (!res || !res?.success) {
          }
          navigate(routes.verifyEmail, {
            state: {
              id: res.data.alumni.recordId,
              email: data.email,
              phone: data.phone,
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }*/
      })
      .catch((err) => console.log("[doGoogleSignin] error: ", err));
  };

  const doFacebookSignin = () => {
    signInWithFacebook()
      .then((res) => {
        console.log(res)
        const googleUser = res?.user;
        setUser({
          firstName: googleUser?.displayName?.split(" ")[0] || "",
          lastName: googleUser?.displayName?.split(" ")[1] || "",
          displayName: googleUser?.displayName,
          email: googleUser?.email,
          phoneNumber: googleUser?.phoneNumber,
          photoURL: googleUser?.photoURL,
        });
      })
      .catch((err) => console.log("[doFacebookSignin] error: ", err));
  };
  const doGithubSignin = () => {
    signInWithGithub()
      .then((res) => {
        const googleUser = res?.user;
        setUser({
          firstName: googleUser?.displayName?.split(" ")[0] || "",
          lastName: googleUser?.displayName?.split(" ")[1] || "",
          displayName: googleUser?.displayName,
          email: googleUser?.email,
          phoneNumber: googleUser?.phoneNumber,
          photoURL: googleUser?.photoURL,
        });
      })
      .catch((err) => console.log("[doGithubSignin] error: ", err));
  };

  const doTwitterSignin = () => {
    signInWithTwitter()
      .then((res) => {
        const googleUser = res?.user;
        setUser({
          firstName: googleUser?.displayName?.split(" ")[0] || "",
          lastName: googleUser?.displayName?.split(" ")[1] || "",
          displayName: googleUser?.displayName,
          email: googleUser?.email,
          phoneNumber: googleUser?.phoneNumber,
          photoURL: googleUser?.photoURL,
        });
      })
      .catch((err) => console.log("[doTwitterSignin] error: ", err));
  };

  const doMicrosoftSignin = () => {
    signInWithMicrosoft()
      .then((res) => {
        const googleUser = res?.user;
        setUser({
          firstName: googleUser?.displayName?.split(" ")[0] || "",
          lastName: googleUser?.displayName?.split(" ")[1] || "",
          displayName: googleUser?.displayName,
          email: googleUser?.email,
          phoneNumber: googleUser?.phoneNumber,
          photoURL: googleUser?.photoURL,
        });
      })
      .catch((err) => console.log("[doMicrosoftSignin] error: ", err));
  };



  return (
    <>
      <Box display={'flex'} justifyContent="space-around">
        <Box className={classes.signupWithItem}>
          <img onClick={doGoogleSignin} className="google" src={Google} alt="" />
        </Box>
        {/* <Box className={classes.signupWithItem}>
          <img onClick={doFacebookSignin} src={Facebook} alt="" />
        </Box> */}
        {/* <Box className={classes.signupWithItem}>
            <img src={LinkedIn} alt="" />
          </Box> */}
      </Box>

      {/* {user && (
        <>
          <box>First name: {user.firstName}</box>
          <box>Last name: {user.lastName}</box>
          <box>Display name: {user.displayName}</box>
          <box>Email: {user.email}</box>
          <box>Phone: {user.phoneNumber || "NA"}</box>
          <box>Photo: </box>
          <img src={user.photoURL} width={"100px"} height={"100px"} />
        </>
      )} */}

    </>
  );
}
