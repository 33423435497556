import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { createDocuments, getSingleEvent } from '../../Utils/apiServices';

import { AuthContext } from '../../Context/authContext';
import Avatar from '@mui/material/Avatar';
import JDIFTBannerSVG from '../../Assets/Svg/eventBanner/JDIFTeventDetailsBanner.svg';
import MANIPALBannerSVG from '../../Assets/Svg/eventBanner/ManipaleventDetailsBanner.svg';
import { Box } from '@mui/system';
import CamSVG from '../../Assets/Svg/cam.svg';
import KenLoader from '../../Components/KenLoader';
import Modal from '@mui/material/Modal';
import OKSVG from '../../Assets/Svg/Ok.svg';
import TVSVG from '../../Assets/Svg/tv.svg';
import ThumbSVG from '../../Assets/Svg/thumb.svg';
import WaitingSVG from '../../Assets/Svg/waiting.svg';
import { activeTheme, getActiveTheme } from '../../Utils/Themes/themeHelper';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";

const bannerBG = {
  MANIPAL: MANIPALBannerSVG,
  JDIFT: JDIFTBannerSVG,
}[getActiveTheme()]

const nameShow = {
  MANIPAL: "MAHE BLR",
  JDIFT: "JD HKV",
}[getActiveTheme()]

const useStyles = makeStyles((theme) => ({
  welcomeBanner: {
    height: '179px',
    background: '#EFEFEF',
    'border-radius': '21.5px',
    backgroundImage: `url(${bannerBG})`,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    textAlign: 'left',
    backgroundSize: 'cover'
  },
  bottomSection: {
    background: '#fff',
    padding: '25px 25px 20px 25px',
    'box-shadow': '0px -7px 104px -20px rgba(0, 0, 0, 0.2)',
    'border-radius': '40px 40px 0px 0px',
    position: 'fixed',
    bottom: 0,
    right: '1px',
    width: 'calc(100% - 129px - 70px)',
  },
  applyNowBtn: {
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    borderRadius: "32px",
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    height: '43px',
    minWidth: '191px',
    "&:hover": {
      cursor: "pointer",
      background: "#fff",
      color: "#FDB813",
      boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
      border: '1px solid #FDB813'

    },
    padding: "10px 10px",
    ['@media (max-width: 800px)']: {
      padding: "10px 6px",
      minWidth: '150px',
      fontSize: '14px'
    },
    ['@media (max-width: 600px)']: {
      padding: "10px 6px",
      minWidth: '120px',
      fontSize: '9px'
    },
  },
  priceTagFree: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '150%',
    color: '#2AA95B',
    paddingTop: '8px',
  },
  applyNowBtn1: {
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    borderRadius: "32px",
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',

    "&:hover": {
      cursor: "pointer",
      background: "#fff",
      color: "#FDB813",
      boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
      border: '1px solid #FDB813'

    },
    minWidth: "10vw",
    padding: "10px 10px",
    marginTop: '50px',
    marginLeft: '35%'

  },
  okImage: {
    padding: '15px',
    background: '#52C15A',
    borderRadius: '50%',
    marginLeft: '40%'
  },
  ThanksText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    /* identical to box height */

    textAlign: 'center',
    color: "#000",
    marginTop: '50px'

  },
  TopTitleTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '28px',
    lineHeight: ' 42px',
    /* identical to box height */
    textAlign: 'left',
    paddingTop: '20px',
    color: activeTheme.general.eventTitleColor,
  },
  TopsubTitleTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: ' 32px',
    /* identical to box height */
    textAlign: 'left',
    paddingTop: '20px',
    color: '#848383', ['@media (max-width: 800px)']: {
      fontSize: '14px',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '14px',
    },

  },
  TopNameTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: ' 22px',
    /* identical to box height */
    textAlign: 'left',
    paddingTop: '20px',
    color: activeTheme.general.eventOrganizer,
    marginLeft: '-34px',
    marginTop: '10px'
  },
  PostTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    /* identical to box height */
    textAlign: 'left',
    paddingTop: '20px',
    color: '#C1C1C1',
    marginRight: '90px',
    ['@media (max-width: 800px)']: {
      marginRight: '90px',
    },
    ['@media (max-width: 600px)']: {
      marginRight: '90px',
    },
    marginTop: '10px'
  },
  TopsecTitleTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: ' 32px',
    /* identical to box height */
    textAlign: 'left',
    paddingTop: '20px',
    color: activeTheme.general.eventTitleColor,
    paddingBottom: '25px'
  },

  iconTitleTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    /* identical to box height */
    textAlign: 'left',
    color: '#848383',
    paddingTop: '5px',
    marginBottom: '20px',
    ['@media (max-width: 800px)']: {
      fontSize: '14px',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '14px',
    },

  },
  bottomTxt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    /* identical to box height */
    textAlign: 'left',
    color: activeTheme.general.eventLabel,
    marginBottom: '10px',
    ['@media (max-width: 800px)']: {
      fontSize: '14px',
      fontWeight: '300',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '12px',
      fontWeight: '300',
    },

  },
  bottomTxtContent: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    /* identical to box height */
    textAlign: 'left',
    color: '#000000',
    ['@media (max-width: 800px)']: {
      fontSize: '16px',
      fontWeight: '300',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '12px',
      fontWeight: '300',
    },
    // marginBottom: '10px'

  },
  bottomPriceContent: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    /* identical to box height */
    textAlign: 'left',
    ['@media (max-width: 800px)']: {
      fontSize: '18px',
      fontWeight: '500',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '12px',
      fontWeight: '300',
    },
    color: activeTheme.general.eventLabel,
    // marginBottom: '10px'

  },
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 300,
  bgcolor: 'background.paper',
  borderRadius: '5px ',
  boxShadow: 24,
  p: 4,
};
export default function EventDetail() {
  let params = useParams()

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [eventDetails, setEventDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const loadEventData = () => {
    setLoading(true);
    getSingleEvent(params.id, user.id)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res.data[0])
          setEventDetails(res.data[0]);
          setLoading(false);
        } else {
          setEventDetails([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadEventData();
  }, []);

  const onRegister = async (e) => {
    setLoading(true);
    try {
      const res = await createDocuments({
        alumniRegisteredEvents: [{ alumniId: user.id, alumniEvent: eventDetails.recordId }]
      });
      if (res?.success === true) {
        handleOpen();
        loadEventData();
      } else {
        return enqueueSnackbar(
          "Error while registering. Please try again later.",
          {
            variant: "error",
          }
        );
      }
    } catch (e) {
      return enqueueSnackbar(
        "Error while registering. Please try again later.",
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box style={{ marginLeft: '-40px', paddingBottom: '160px' }}>
        {loading && <KenLoader />}
        <Box className={classes.welcomeBanner} xs={12}>
        </Box>

        <Box>
          <Typography className={classes.TopTitleTxt}>{eventDetails.name}
          </Typography>
          <Typography className={classes.TopsubTitleTxt}>
            {eventDetails.description}
          </Typography>
          <Grid container style={{ paddingTop: 10 }} spacing={1}>
            <Grid item xs={1} >

              {eventDetails.attachments && eventDetails.attachments.slice(0, 1).map((item) => {
                return <Avatar alt={eventDetails.name} src={`data:image/png;base64,` + item.body} />

              })}
            </Grid>

            {eventDetails.name && <Grid className={classes.TopNameTxt} item xs={1.5} > {nameShow}</Grid>}
            {eventDetails.createdDate && <Grid className={classes.PostTxt} item xs={3} >
              posted {moment(eventDetails.createdDate).fromNow()}

            </Grid>
            }
          </Grid>
          {eventDetails.whatDoYouGet && <Typography className={classes.TopsecTitleTxt}>What You Get</Typography>}
          {eventDetails.whatDoYouGet && <>
            <Grid container spacing={1}>
              {eventDetails.whatDoYouGet && eventDetails.whatDoYouGet.split('\r\n').map((item, index) => (
                <Grid item xs={6} >
                  <Grid container spacing={1}>
                    <Grid item xs={1} >
                      <img alt="Remy Sharp" src={ThumbSVG} />
                    </Grid>
                    <Grid item xs={9} >
                      <Typography className={classes.iconTitleTxt}>{item}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>}
        </Box>

        {eventDetails.alumniDate &&
          <>
            <Box>
              <div className={classes.bottomSection}>   <Grid container spacing={1}>
                <Grid item xs={2}  >
                  <Typography className={classes.bottomTxt}>Date
                  </Typography>
                  <Typography className={classes.bottomTxtContent}>
                    {moment(eventDetails.alumniDate).format('D MMM YYYY')}
                  </Typography>
                </Grid>
                <Grid item xs={2} >
                  <Typography className={classes.bottomTxt}>Time
                  </Typography>
                  <Typography className={classes.bottomTxtContent}>
                    {moment(`${eventDetails.alumniDate}T${eventDetails.alumniTime}`).utc().format('hh:mm a')}
                  </Typography>
                </Grid>
                <Grid item xs={2} >
                  <Typography className={classes.bottomTxt}>Duration
                  </Typography>
                  <Typography className={classes.bottomTxtContent}>
                    {eventDetails.duration}
                  </Typography>
                </Grid>
                <Grid item xs={2} >
                  {eventDetails.price == 0 || eventDetails.price == null ? (
                    <Typography className={classes.priceTagFree}>Free</Typography>
                  ) : (
                    <>
                      <Typography className={classes.bottomTxt}>Price</Typography>
                      <Typography className={classes.bottomPriceContent}>
                        Rs.   {eventDetails.price}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={4} sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }} >
                  <Button
                    size="small"
                    variant="contained"
                    className={classes.applyNowBtn}
                    onClick={eventDetails.applied === 'false' ? onRegister : null}
                  >
                    {eventDetails.applied === 'false' ? 'Register Now' : 'Registered'}
                  </Button>
                </Grid>


              </Grid></div>
            </Box>
          </>
        }
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>

            <div
            >

              <img alt="Remy Sharp" className={classes.okImage} src={OKSVG} />
            </div>

            <Typography className={classes.ThanksText} id="modal-modal-description" sx={{ mt: 2 }}>
              Thank you for Registering
            </Typography>
            <Button
              size="small"
              variant="contained"
              className={classes.applyNowBtn1}
              onClick={handleClose}
            >
              Close

            </Button>
          </Box>

        </Modal>
      </Box>
    </>
  );
}
