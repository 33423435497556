import { axiosInstance } from "../../Utils/apiServices";
import moment from 'moment';

/**
 * Send/Resend OTP to email or mobile
 * @param {{id: string}} payload will have either mobile or email
 */
export const requestForOTP = async (payload) => {
  const path = `/auth/otp/send/${payload.id}`;
  return axiosInstance
    .request({ url: `${path}`, method: "post" })
    .then((res) => res.data);
};

/**
 * Verify OTP for user login or signup
 * @param {{id: string, otp: string}} payload will verify otp for email or mobile
 */
export const verifyOTP = async (payload) => {
  const path = `/auth/otp/verify/${payload.id}/${payload.otp}`;
  return axiosInstance
    .request({ url: `${path}`, method: "get" })
    .then((res) => res.data);
};

/**
 * User Signup Request
 * @param {*} payload
 */
export const signUp = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/alumni/save`;

  return axiosInstance
    .request({
      url: `${path}`,
      method: "post",
      data: {
        alumni: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          yearOfGraduation: moment(payload.date).format('YYYY'),
          programId: payload.program
        },
      },
    })
    .then((res) => res.data);
};
