import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import KenSelect from '../../../Components/KenSelect';
import KenTextField from "../../../Components/KenTextField";
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';
import KenIcon from "../../../Components/KenIcon";
import ErrorIcon from '../../../Assets/Icons/error.png';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import './EducationDetails.css'
const Educationdetails = () => {

  const [dataList, setDataList] = useState([]);
  const initialObject = {
    university: "",
    universityError: "",
    education: "",
    educationError: "",
    degree: "",
    degreeError: ""
  }
  const errorMessage = "Field is required.";
  const [dataObject, setDataObject] = useState(initialObject)
  const educationOption = [
    {
      label: 'MSCIT',
      value: 'MSCIT'
    },
    {
      label: 'BSCIT',
      value: 'BSCIT'
    }
  ]
  const validateForm = () => {
    if (dataObject.university !== "" && dataObject.education !== "" && dataObject.degree !== "") {
      return true;
    } else {
      setDataObject({
        ...dataObject,
        universityError: dataObject.university === "" ? errorMessage : "",
        educationError: dataObject.education === "" ? errorMessage : "",
        degreeError: dataObject.degree === "" ? errorMessage : "",
      })
      return false
    }
  }
  const handleOnChange = (key, value, keyError) => {
    setDataObject({ ...dataObject, [key]: value, [keyError]: value === "" ? errorMessage : "" })
  }

  const editData = (label, value, errorLabel, index) => {
    const obj = {
      ...dataList[index], [label]: value,
      [errorLabel]: value === '' ? errorMessage : ''
    };
    dataList[index] = { ...obj };
    setDataList([...dataList])
  };
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClickOpen = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
  };
  const handleDelete = () => {
    dataList.splice(selectedIndex, 1);
    setDataList([...dataList]);
    handleClose();
  };

  return (
    <div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontSize: '15px' }} id="alert-dialog-title">
            {"Are you sure?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Grid container xs={12}>
        {dataList?.length > 0 && dataList.map((item, index) => {
          return (
            <Grid xs={12} style={{ display: "flex" }}>
              <Grid xs={4}>
                <Typography className="Contentcss">University/College/Institute Name<span style={{ color: "red" }}>*</span></Typography>
                <Box display="flex" alignItems="flex-start">
                  <KenTextField
                    required
                    placeholder="Enter University/College/Institute"
                    value={item.university}
                    error={item.universityError}
                    onChange={(e) => { editData("university", e.target.value, "universityError", index) }}
                  />
                  {dataObject.universityError !== "" && <Box width="16px" height="16px" margin="19px 8px">
                    <KenIcon
                      iconType="img"
                      icon={ErrorIcon}
                      variant="extraSmall"
                    ></KenIcon>
                  </Box>}

                </Box>
              </Grid>
              <Grid xs={2.5} style={{ marginLeft: '10px' }}>
                <Typography className="Contentcss">Education Type<span style={{ color: "red" }}>*</span></Typography>
                <KenSelect
                  name="education"
                  required
                  value={item.education}
                  select
                  options={educationOption}
                  error={item.educationError}
                  handleChange={(e) => { editData("education", e.target.value, "educationError", index) }}
                />
              </Grid>
              <Grid xs={2.5} style={{ marginLeft: '10px' }}>
                <Typography className="Contentcss">Degree<span style={{ color: "red" }}>*</span></Typography>
                <Box display="flex" alignItems="flex-start">
                  <KenTextField
                    placeholder="Enter Degree"
                    value={item.degree}
                    error={item.degreeError}
                    onChange={(e) => { editData("degree", e.target.value, "degreeError", index) }}
                  />
                  {item.degreeError !== "" &&
                    <Box width="16px" height="16px" margin="19px 8px">
                      <KenIcon
                        iconType="img"
                        icon={ErrorIcon}
                        variant="extraSmall"
                      ></KenIcon>
                    </Box>
                  }
                </Box>
              </Grid>
              <Grid xs={1} onClick={() => { handleClickOpen(index) }}>
                <Tooltip title="Delete">
                  <DeleteIcon style={{ cursor: "pointer", marginTop: '40px' }} />
                </Tooltip>
              </Grid>
            </Grid>
          )
        })}
        <>
          <Grid xs={4}>
            <Typography className="Contentcss">University/College/Institute Name<span style={{ color: "red" }}>*</span></Typography>
            <Box display="flex" alignItems="flex-start">
              <KenTextField
                required
                placeholder="Enter University/College/Institute"
                value={dataObject.university}
                error={dataObject.universityError}
                onChange={(e) => { handleOnChange("university", e.target.value, "universityError") }}
              />
              {dataObject.universityError !== "" && <Box width="16px" height="16px" margin="19px 8px">
                <KenIcon
                  iconType="img"
                  icon={ErrorIcon}
                  variant="extraSmall"
                ></KenIcon>
              </Box>}

            </Box>
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px' }}>
            <Typography className="Contentcss">Education Type<span style={{ color: "red" }}>*</span></Typography>
            <KenSelect
              name="education"
              required
              value={dataObject.education}
              select
              options={educationOption}
              error={dataObject.educationError}
              handleChange={(e) => { handleOnChange("education", e.target.value, "educationError") }}
            />
          </Grid>
          <Grid xs={2.5} style={{ marginLeft: '10px' }}>
            <Typography className="Contentcss">Degree<span style={{ color: "red" }}>*</span></Typography>
            <Box display="flex" alignItems="flex-start">
              <KenTextField
                placeholder="Enter Degree"
                value={dataObject.degree}
                error={dataObject.degreeError}
                onChange={(e) => { handleOnChange("degree", e.target.value, "degreeError") }}
              />
              {dataObject.degreeError !== "" &&
                <Box width="16px" height="16px" margin="19px 8px">
                  <KenIcon
                    iconType="img"
                    icon={ErrorIcon}
                    variant="extraSmall"
                  ></KenIcon>
                </Box>
              }
            </Box>
          </Grid>
        </>
      </Grid>
      <div style={{ display: 'flex' }}>
        <Link underline="none" style={{ cursor: "pointer", marginTop: '15px', color: '#000000', fontWeight: 500 }}
          onClick={() => {
            if (validateForm()) {
              setDataList([...dataList, { ...dataObject }]);
              setDataObject({ ...initialObject })
            }
          }}>+ Add New Education History</Link>
      </div>
    </div>

  )
}
export default Educationdetails