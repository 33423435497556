import { Box, Typography, Button, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import KenSelect from '../../../Components/KenSelect';
import KenTextField from "../../../Components/KenTextField";
import Link from '@mui/material/Link';
import KenIcon from "../../../Components/KenIcon";
import ErrorIcon from '../../../Assets/Icons/error.png';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import './WorkExperience.css';
const Workexperience = () => {
  const [dataList1, setdataList1] = useState([]);
  const initialObject = {
    workexperience: "",
    workexperienceError: "",
    industries: "",
    industriesError: "",
    skills: "",
    skillsError: "",
    jobrole: "",
    jobroleError: "",
    companyname: "",
    companyNameError: "",
    years: "",
    yearsError: ""
  }
  const errorMessage = "Field is required.";
  const [dataObject1, setDataObject1] = useState(initialObject)
  const workexperience = [
    {
      label: 'IT',
      value: 'IT'
    },
    {
      label: 'Marketing',
      value: 'Marketing'
    }
  ]
  const industries = [
    {
      label: 'Education',
      value: 'Education'
    },
    {
      label: 'Mechanical',
      value: 'Mechanical'
    }
  ]
  const skills = [
    {
      label: 'React',
      value: 'React'
    },
    {
      label: 'C',
      value: 'C'
    }
  ]
  const years = [
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    }
  ]
  const validateForm = () => {
    console.log("hello1")
    if (dataObject1.workexperience !== "" && dataObject1.industries !== "" && dataObject1.skills !== "" && dataObject1.years !== "" && dataObject1.jobrole !== "" && dataObject1.companyname !== "") {
      console.log("hello2")
      return true;
    } else {
      console.log("hello3")
      setDataObject1({
        ...dataObject1,
        workexperienceError: dataObject1.workexperience === "" ? errorMessage : "",
        industriesError: dataObject1.industries === "" ? errorMessage : "",
        skillsError: dataObject1.skills === "" ? errorMessage : "",
        yearsError: dataObject1.years === "" ? errorMessage : "",
        jobroleError: dataObject1.jobrole === "" ? errorMessage : "",
        companyNameError: dataObject1.companyname === "" ? errorMessage : "",
      })
      return false
    }
  }
  const handleOnChange = (key, value, keyError) => {
    setDataObject1({ ...dataObject1, [key]: value, [keyError]: value === "" ? errorMessage : "" })
  }
  const editData = (label, value, errorLabel, index) => {
    const obj = {
      ...dataList1[index], [label]: value,
      [errorLabel]: value === '' ? errorMessage : ''
    };
    dataList1[index] = { ...obj };
    setdataList1([...dataList1])
  };
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClickOpen = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
  };
  const handleDelete = () => {
    dataList1.splice(selectedIndex, 1);
    setdataList1([...dataList1]);
    handleClose();
  };
  return <>
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ fontSize: '15px' }} id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    {dataList1?.length > 0 && dataList1.map((item, index) => {
      return (
        <>
          <Grid container xs={12}>
            <Grid xs={2.5}>
              <Typography className="Contentcss">Total Work Experience<span style={{ color: "red" }}>*</span></Typography>
              <KenSelect
                name="work Experience"
                required
                value={item.workexperience}
                select
                options={workexperience}
                error={item.workexperienceError}
                handleChange={(e) => { editData("workexperience", e.target.value, "workexperienceError", index) }}
              />
            </Grid>
            <Grid xs={2.5} style={{ marginLeft: '5px' }}>
              <Typography className="Contentcss">Industries<span style={{ color: "red" }}>*</span></Typography>
              <KenSelect
                name="industries"
                required
                value={item.industries}
                select
                options={industries}
                error={item.industriesError}
                handleChange={(e) => { editData("industries", e.target.value, "industriesError", index) }}
              />
            </Grid>
            <Grid xs={2.5} style={{ marginLeft: '5px' }}>
              <Typography className="Contentcss">Skills<span style={{ color: "red" }}>*</span></Typography>
              <KenSelect
                name="Skills"
                required
                value={item.skills}
                select
                options={skills}
                error={item.skillsError}
                handleChange={(e) => { editData("skills", e.target.value, "skillsError", index) }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ display: "flex", marginTop: '10px' }}>
            <Grid xs={4}>
              <Typography className="Contentcss">Job Role<span style={{ color: "red" }}>*</span></Typography>
              <Box display="flex" alignItems="flex-start">
                <KenTextField
                  required
                  placeholder="Enter Job Role"
                  value={item.jobrole}
                  error={item.jobroleError}
                  onChange={(e) => { editData("jobrole", e.target.value, "jobroleError", index) }}
                />
                {item.jobroleError !== "" && <Box width="16px" height="16px" margin="19px 8px">
                  <KenIcon
                    iconType="img"
                    icon={ErrorIcon}
                    variant="extraSmall"
                  ></KenIcon>
                </Box>}
              </Box>
            </Grid>
            <Grid xs={3.2} marginLeft='5px'>
              <Typography className="Contentcss">Company Name<span style={{ color: "red" }}>*</span></Typography>
              <Box style={{display:'flex' ,alignItems:'flex-start'}}>
                <KenTextField
                  required
                  placeholder="Enter companyname"
                  value={item.companyname}
                  error={item.companynameError}
                  onChange={(e) => { editData("companyname", e.target.value, "companyNameError", index) }}
                />
                {item.companyNameError !== "" && <Box width="16px" height="16px" margin="19px 8px">
                  <KenIcon
                    iconType="img"
                    icon={ErrorIcon}
                    variant="extraSmall"
                  ></KenIcon>
                </Box>}
              </Box>
            </Grid>
            <Grid xs={2.5} style={{marginLeft: '5px'}}>
              <Typography className="Contentcss">Number of Years<span style={{ color: "red" }}>*</span></Typography>
              <Box style={{ alignItems: "flex-start" }} >
                <KenSelect
                  name="years"
                  required
                  value={item.years}
                  select
                  options={years}
                  error={item.yearsError}
                  handleChange={(e) => { editData("years", e.target.value, "yearsError", index) }}
                />
                {item.yearsError !== "" && <Box width="16px" height="16px" margin="19px 8px">
                  <KenIcon
                    iconType="img"
                    icon={ErrorIcon}
                    variant="extraSmall"
                  ></KenIcon>
                </Box>}
              </Box>
            </Grid>
            <Grid xs={1} style={{ marginBottom: '15px' }} onClick={() => { handleClickOpen(index) }}>
              <Tooltip title="Delete">
                <DeleteIcon style={{ cursor: "pointer", marginTop: '40px' }} />
              </Tooltip>
            </Grid>
          </Grid>
        </>)
    })}
    <>
      <Grid container xs={12}>
        <Grid xs={2.5} style={{ marginRight: '10px' }}>
          <Typography className="Contentcss">Total Work Experience<span style={{ color: "red" }}>*</span></Typography>
          <KenSelect
            name="work Experience"
            required
            value={dataObject1.workexperience}
            select
            options={workexperience}
            error={dataObject1.workexperienceError}
            handleChange={(e) => { handleOnChange("workexperience", e.target.value, "workexperienceError") }}
          />
        </Grid>
        <Grid xs={2.5}>
          <Typography className="Contentcss">Industries<span style={{ color: "red" }}>*</span></Typography>
          <KenSelect
            name="industries"
            required
            value={dataObject1.industries}
            select
            options={industries}
            error={dataObject1.industriesError}
            handleChange={(e) => { handleOnChange("industries", e.target.value, "industriesError") }}
          />
        </Grid>
        <Grid xs={2.5} style={{ marginLeft: '10px' }}>
          <Typography className="Contentcss">skills<span style={{ color: "red" }}>*</span></Typography>
          <KenSelect
            name="skills"
            required
            value={dataObject1.skills}
            select
            options={skills}
            error={dataObject1.skillsError}
            handleChange={(e) => { handleOnChange("skills", e.target.value, "skillsError") }}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ display: "flex" }}>
        <Grid xs={4} style={{ marginRight: '10px', marginTop: '10px', width: '350px' }}>
          <Typography className="Contentcss">Job Role<span style={{ color: "red" }}>*</span></Typography>
          <Box display="flex" alignItems="flex-start">
            <KenTextField
              required
              placeholder="Enter Job Role"
              value={dataObject1.jobrole}
              error={dataObject1.jobroleError}
              onChange={(e) => { handleOnChange("jobrole", e.target.value, "jobroleError") }}
            />
            {dataObject1.jobroleError !== "" && <Box width="16px" height="16px" margin="19px 8px">
              <KenIcon
                iconType="img"
                icon={ErrorIcon}
                variant="extraSmall"
              ></KenIcon>
            </Box>}
          </Box>
        </Grid>
        <Grid xs={3.5}>
          <Typography className="Contentcss" style={{ marginTop: '10px' }}>Company Name<span style={{ color: "red" }}>*</span></Typography>
          <Box style={{display:'flex' ,alignItems:'flex-start'}}>
            <KenTextField
              required
              placeholder="Enter companyname"
              value={dataObject1.companyname}
              error={dataObject1.companyNameError}
              onChange={(e) => { handleOnChange("companyname", e.target.value, "companyNameError") }}
            />
            {dataObject1.companyNameError !== "" && <Box width="16px" height="16px" margin="19px 8px">
              <KenIcon
                iconType="img"
                icon={ErrorIcon}
                variant="extraSmall"
              ></KenIcon>
            </Box>}
          </Box>
        </Grid>
        <Grid xs={2.5} style={{ marginTop: '10px' }}>
          <Typography className="Contentcss" style={{ marginLeft: '15px' }}>Number of Years<span style={{ color: "red" }}>*</span></Typography>
          <Box style={{ alignItems: "flex-start", marginLeft: '20px' }} >
            <KenSelect
              name="years"
              required
              value={dataObject1.years}
              select
              options={years}
              error={dataObject1.yearsError}
              handleChange={(e) => { handleOnChange("years", e.target.value, "yearsError") }}
            />
            {dataObject1.yearsError !== "" && <Box width="16px" height="16px" margin="19px 8px">
            </Box>}
          </Box>
        </Grid>
      </Grid>
    </>
    <div style={{ display: 'flex' }}>
      <Link underline="none" style={{ cursor: "pointer", marginTop: '15px', color: '#000000', fontWeight: 500 }}
        onClick={() => {
          if (validateForm()) {
            setdataList1([...dataList1, { ...dataObject1 }]);
            setDataObject1({ ...initialObject })
          }
        }}>+ Add New Work Experience</Link>
    </div>
  </>
}
export default Workexperience;