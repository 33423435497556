import { ClassNames } from '@emotion/react';
import { minHeight } from '@mui/system';
import React, { useState } from 'react';
import KenAccordion from '../../../Components/KenAccordion';
import AchievementsChild from './AchievementsChild';
import AwardsChild from './AwardsChild';
import BooksChild from './BooksChild';
import ParentsChild from './ParentsChild';
import SportsChild from './SportsChild';

export const Achievements = () => {
  const AchievementsData = [
    {
      id: 1,
      label: 'Academic Achievements',
      component: <AchievementsChild />,
      expanded: true,
    },
    {
      id: 2,
      label: 'Sports Achievements',
      component: <SportsChild />,
      expanded: true,
    },
    {
      id: 3,
      label: 'Awards & Honors',
      component: <AwardsChild />,
      expanded: true,
    },
    {
      id: 4,
      label: 'Patents + Publications',
      component: <ParentsChild />,
      expanded: true,
    },
    {
      id: 5,
      label: 'Books Authored',
      component: <BooksChild />,
      expanded: true,
    },
    // {
    //   id: 6,
    //   label: 'Demo',
    //   component: <Demo />,
    //   expanded: true,
    // },
  ];
  const [achievementArray, setAchievementArray] = useState(AchievementsData);
  return (
    <div>
      {achievementArray.map((item, index) => {
        return (
          <KenAccordion 
          label={item.label}
          children={item.component}
          expanded={item.expanded}
          key={index}
            handleChange={() => {
              achievementArray[index].expanded =
                !achievementArray[index].expanded;
              setAchievementArray([...achievementArray]);
            }}
          />
        );;
      })}
    </div>
  );
};


