import './index.css';

import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { activeTheme, getActiveTheme, THEME_NAMES } from '../../Utils/Themes/themeHelper';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DriveEta } from '@mui/icons-material';
import DummyImagesSVG from '../../Assets/Svg/DummyImage.svg';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import JDIFTSVG from '../../Assets/Svg/eventBanner/eventsBanner.svg';
import KenLoader from '../../Components/KenLoader';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ManipalSVG from '../../Assets/Svg/eventBanner/manipal-banner.svg';
import NSOMSVG from '../../Assets/Svg/eventBanner/manipal-banner.svg';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { getAllEvents } from '../../Utils/apiServices';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { padding } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const BannerSVG = {
  MANIPAL: ManipalSVG,
  JDIFT: JDIFTSVG,
  NSOM: NSOMSVG,
  ken42: JDIFTSVG,
}[getActiveTheme()];

const getDescription = () => {
  let desc = '';
  switch (getActiveTheme()) {
    case THEME_NAMES.JDIFT:
      desc = "Join the world of online learning, with top rated courses from leading professors accross the world. Brought to you in      collaboration with JD Institute of Fashion & Technology!";
      break;
    case THEME_NAMES.MANIPAL:
      desc = "Join the world of online learning, with top rated courses from leading professors accross the world. Brought to you in      collaboration with Manipal Academy of Higher Education!";
      break;
    case THEME_NAMES.NSOM:
      desc = "Join the world of online learning, with top rated courses from leading professors accross the world. Brought to you in      collaboration with NITTE School of Management!";
      break;
    case THEME_NAMES.KEN42:
      desc = "Join the world of online learning, with top rated courses from leading professors accross the world. Brought to you in      collaboration with JD Institute of Fashion & Technology!";
      break;
    default:
      desc = "Join the world of online learning, with top rated courses from leading professors accross the world. Brought to you in      collaboration with JD Institute of Fashion & Technology!";
  }
  return desc;
}
const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '0px',
  },
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),

    // '& .slick-arrow.slick-next': {
    //   '&::before': {
    //     color: 'red',
    //     content: `url(${RightArrow})`,
    //   },
    // },

    // '& .slick-arrow.slick-prev': {
    //   '&::before': {
    //     color: 'red',
    //     content: `url(${LeftArrow})`,
    //   },
    // },
  },

  welcomeBanner: {
    height: '179px',
    background: activeTheme.general.eventBannerBG,
    'border-radius': '21.5px',
    backgroundImage: `url(${BannerSVG})`,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    textAlign: 'left',
    backgroundSize: 'cover',
  },
  FilterSection: {
    background: '#F5FEFF',
    'border-radius': '21.5px',
    marginTop: '30px',
    padding: '15px',
    ['@media (max-width: 800px)']: {
      width: '95vw',
    },

  },
  TextGrid: {
    margin: '20px 20px',
    minWidth: 330,
  },
  bannerTextTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#000000',
    width: 'calc(100% - 100px)',
    ['@media (max-width: 800px)']: {
      marginTop: '0px',
      fontSize: '16px'
    },
    ['@media (max-width: 600px)']: {
      marginTop: '0px',
      fontSize: '16px'
    },
  },
  bannerTextDescription: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '27px',
    color: '#767a7a',
    paddingTop: '10px',
    ['@media (max-width: 800px)']: {
      marginTop: '0px',
      fontSize: '12px'
    },
    ['@media (max-width: 600px)']: {
      marginTop: '0px',
      fontSize: '12px'
    },
  },
  applyNowBtn: {
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    borderRadius: '32px',
    boxShadow:
      '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      cursor: 'pointer',
      background: '#fff',
      color: '#FDB813',
      boxShadow:
        '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
      border: '1px solid #FDB813',
    },
    minWidth: '10vw',
    padding: '10px 10px',
    marginTop: '120%',
  },
  date: {
    background: activeTheme.buttonPrimary.backgroundColor,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',

    '& .month': {
      'font-weight': '500',
      'line-height': '18px',
    },
  },
  cardTitle: {
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '16px',
    'line-height': '150%',
    color: '#000000',
    marginBottom: theme.spacing(1),
  },
  cardSub: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '150%',
    color: '#6A6A6A',
    textAlign: "left"
  },
  cardLink: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '150%',
    color: '#6A6A6A',
    textAlign: 'left',
  },
  priceTag: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '28px',
    color: '#F2564A',
    paddingTop: '8px',
  },
  priceTagFree: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    color: '#2AA95B',
    paddingTop: '8px',
  },
  '& .css-1l1yuse': {
    padding: '100px 0px 0px 0px !important',
  },
  knowMore: {
    alignSelf: 'flex-end',
    color: '#0052B4',
    'font-size': '12px',
    'line-height': '100%',

    '&:hover': {
      background: 'transparent'
    }
  }
}));

export default function Events() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [type, setType] = React.useState('');
  const [date, setDate] = React.useState(null);

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  const handleChangeForLocationSelect = (event) => {
    setLocation(event.target.value);
  };
  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const [options, setOptions] = useState({
    location: [],
    category: [],
    type: []
  });

  const handleData = (e) => {
    e.preventDefault();
    console.log('handleDatahandleDatahandleDatahandleData');
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  }

  const handleTypeChange = (e) => {
    setType(e.target.value);
  }

  const [events, setEvents] = useState([]);

  // const getAllEventss = () => {
  //   getAllEvents()
  //     .then((res) => {
  //       if (res?.data?.length > 0) {
  //         setEvents([...res.data]);
  //         // setLoading(false);
  //       } else {
  //         setEvents([...res?.data]);
  //         // setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // setLoading(false);
  //     });
  // }

  const onChangePage = (id) => {
    // window.location.assign(`/event-details/${id}`)
    navigate(`/event-details/${id}`);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getAllEvents()
      .then((res) => {
        if (res?.data?.length > 0) {
          setEvents([...res.data]);
          setOptions({
            location: Array.from(new Set(res.data.map(i => i.location))).map(i => ({ value: i, name: i })),
            category: Array.from(new Set(res.data.map(i => i.category))).map(i => ({ value: i, name: i })),
            type: Array.from(new Set(res.data.map(i => i.type))).map(i => ({ value: i, name: i })),
          });
          setLoading(false);
        } else {
          setEvents([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }, []);

  const getFilteredData = () => {

    let filteredData = [...events];
    if (search && search.length > 0) {
      filteredData = filteredData.filter(i => i.name?.toLowerCase().includes(search?.toLowerCase()));
    }

    if (category) {
      filteredData = filteredData.filter(i => i.category === category);
    }

    if (type) {
      filteredData = filteredData.filter(i => i.type === type);
    }

    if (location) {
      filteredData = filteredData.filter(i => i.location === location);
    }

    if (date) {
      filteredData = filteredData.filter(i => moment(i.alumniDate).isSameOrAfter(moment(date)));
    }

    return filteredData;
  }

  return (
    <>
      <Box >
        {loading && <KenLoader />}

        {/* Banner Section */}
        <Box className={classes.welcomeBanner} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.TextGrid}>
              <Typography className={classes.bannerTextTitle}>
                Digital Marketing Seminar
              </Typography>
              <div className={classes.bannerTextDescription}>
                {getDescription()}
              </div>
            </Grid>
            <Grid item xs={3}>
              <Typography></Typography>
            </Grid>
            <Grid item xs={1}>
              {/* <Button
                size="small"
                variant="contained"
                className={classes.applyNowBtn}
              >
                Know More  &gt;
              </Button> */}
            </Grid>
          </Grid>
        </Box>

        {/* Filter Section */}
        <Box className={classes.FilterSection} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  height: 40,
                  width: 250, ['@media (max-width: 800px)']: {
                    width: 190,
                  },
                  borderRadius: '30px',
                  boxShadow: 'none',
                }}
              >
                <TextField
                  className="inputFil"
                  value={search}
                  onChange={handleChange}
                  placeholder='Search'
                />
                <IconButton
                  onClick={handleData}
                  type="submit"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={2}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  height: 40,
                  width: 150,
                  boxShadow: 'none',
                  borderRadius: '30px',
                }}
              >
                {' '}
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel className="111212132" id="demo-select-small">
                    Location
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={location}
                    onChange={handleChangeForLocationSelect}
                    className="dropDownCss"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {options.location.map((item) => {
                      return (
                        <MenuItem value={item.value}> {item.name} </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  height: 40,
                  width: 200,
                  boxShadow: 'none',
                  borderRadius: '30px',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack className="dateRem" spacing={3}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={date}
                      onChange={handleChangeDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  height: 40,
                  boxShadow: 'none',
                  width: 150,
                  ['@media (max-width: 800px)']: {
                    width: 120,
                  },
                  borderRadius: '30px',
                }}
              >
                <FormControl sx={{
                  m: 1, minWidth: 120, ['@media (max-width: 800px)']: {
                    width: 80,
                  },
                }} size="small">
                  <InputLabel className="111212132" id="demo-select-small">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={category}
                    onChange={handleCategoryChange}
                    className="dropDownCss"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {options.category.map((item) => {
                      return (
                        <MenuItem value={item.value}> {item.name} </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 150,
                  boxShadow: 'none',
                  height: 40,
                  borderRadius: '30px',
                }}
              >
                <FormControl sx={{
                  m: 1, minWidth: 120, ['@media (max-width: 800px)']: {
                    width: 40,
                  },
                }} size="small">
                  <InputLabel className="111212132" id="demo-select-small">
                    Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={type}
                    onChange={handleTypeChange}
                    className="dropDownCss"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {options.type.map((item) => {
                      return (
                        <MenuItem value={item.value}> {item.name} </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box xs={12}>
          <Grid container spacing={1}>
            {getFilteredData().map((item, index) => {
              return (
                <Grid
                  key={item.recordId}
                  item
                  xs={3}
                  className={classes.TextGrid}
                >
                  <Card style={{ borderRadius: '15px' }} sx={{ maxWidth: 380 }}>
                    {item.attachments.length === 0 ? (
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="auto"
                        width="331"
                        image={DummyImagesSVG}
                      // image={`data:image/jpeg;base64,${item.attachments[0].body}`}
                      />
                    ) : (
                      item.attachments.slice(0, 1).map((item) => {
                        return (
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="176"
                            width="331"
                            image={`data:image/png;base64,` + item.body}
                          // image={`data:image/jpeg;base64,${item.attachments[0].body}`}
                          />
                        );
                      })
                    )}
                    <CardContent sx={{ paddingBottom: '10px !important' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} display='flex' justifyContent='space-between' alignItems='center' flexDirection='column'>
                          <div
                            className={classes.date}
                            style={{
                              borderRadius: '50%',
                              color: 'white',
                              height: '60px',
                              width: '60px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            {moment(item.alumniDate).format('D')}
                            <span className='month'>{moment(item.alumniDate).format('MMM')}</span>
                          </div>

                          {item.price == 0 || item.price == null ? (
                            <Typography className={classes.priceTagFree}>Free</Typography>
                          ) : (
                            <Typography className={classes.priceTag}>
                              INR {item.price}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={8} display='flex' justifyContent='space-between' alignItems='flex-start' flexDirection='column'>
                          <Box display='flex' justifyContent='flex-start' alignItems='flex-start' flexDirection='column'>
                            <Typography
                              className={classes.cardTitle}
                              component="div"
                            >
                              {item.name}
                            </Typography>
                            <Typography className={classes.cardSub}>
                              {item.description && item.description.length > 50 ? item.description.substring(0, 50) + '...' : item.description}
                            </Typography>
                          </Box>
                          <Button
                            className={classes.knowMore}
                            size="small"
                            onClick={() => {
                              onChangePage(item.recordId);
                            }}
                          >
                            Know More &gt;
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {/* <CardActions>

                </CardActions> */}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
