import { Avatar, Box, Grid, Popover, Toolbar, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import { AuthContext } from '../../Context/authContext';
import KenAutoComplete from '../KenAutoComplete';
import MuiAppBar from '@mui/material/AppBar';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: 124,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: 28,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  // backgroundColor: theme.palette.KenColors.background,
  backgroundColor: theme.palette.KenColors.kenWhite,
  paddingTop: 8,
  [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
    paddingLeft: 0,
    width: '100%',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    // background: theme.palette.KenColors.background,
    backgroundColor: theme.palette.KenColors.kenWhite,
    minHeight: '56px !important',
  },
  icon: {
    color: theme.palette.KenColors.kenBlack,
  },
  iconWrapper: {
    // padding: "8px 12px",
    background: '#F4F5F7',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
  name: {
    color: theme.palette.KenColors.kenBlack,
    fontWeight: 500,
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'flex-start',
    'align-items': 'flex-start',

    '& .class': {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '10px',
      'line-height': '100%',
      color: '#505F79',
    },
  },
  avatar: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  appbarRoot: {
    [theme.breakpoints.only('xs')]: {
      right: 'auto',
      paddingLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'box-shadow': '0px 1px 12px rgb(0 0 0 / 25%)',
      // Note: added so header shadow shows on top of add task modal
      zIndex: '1301',
    },
  },
  menuToggle: {
    display: 'none',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'block',
      ['@media (max-width: 600px)']: {
        marginRight: '90%',
      }
    },
  },
}));

export const getApplicantName = (user) => {
  let name = user?.alumniName?.split(' ')[0].length < 13
    ? user?.alumniName?.split(' ')[0]
    : `${user?.alumniName
      ?.split(' ')[0][0]
      .toUpperCase()} ${user?.alumniName?.split(' ')[1][0].toUpperCase()}`;
  return name.split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
};

export default function MenuAppBar(props) {
  const classes = useStyles();
  const { open, setSwipeableDrawer } = props;
  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [data, setData] = React.useState();
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const navigation = useNavigate()
  const onProfile = () => {
    const items = JSON.parse(localStorage.getItem('user'));
    navigation(`/profile/${items.alumniId}`)
  }

  return (
    <AppBar
      position="fixed"
      open={open}
      elevation={0}
      classes={{ root: classes.appbarRoot }}
    >
      <Toolbar classes={{ root: classes.root }} variant="regular">
        <Grid container direction="row" spacing={2}>
          <Grid md={8} sm={8} xs={10} container alignItem="center" justifyContent="center" direction="row">
            <Grid xs={2} sx={{ mt: 1 }} className={classes.menuToggle}>
              <ReorderOutlinedIcon
                className={classes.icon}
                onClick={() => { setSwipeableDrawer(true); }}
              />
            </Grid>
            {/* <Grid item md={12} tablet={12} xs={10}>
              <KenAutoComplete
                options={[]}
                placeholder={'Search...'}
                setData={setData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleSearch={() => {
                  //TODO: add logic for search
                }}
              />
            </Grid> */}
          </Grid>
          <Grid md={4} sm={4} xs={2} container alignItems="center" justifyContent="flex-end" direction="row">
            {/* <Grid item>
              <Box className={classes.iconWrapper}>
                <NotificationsNoneIcon className={classes.icon} />
              </Box>
            </Grid> */}
            <Grid
              sx={{
                display: { xs: 'none', sm: 'block' },
                marginLeft: theme.spacing(3),
              }}
            >
              <Typography className={classes.name}>
                {getApplicantName(user)}
                <span className="class">{user.yearOfGraduation && "Class of " + user.yearOfGraduation}</span>
              </Typography>
            </Grid>
            <Grid
              // item
              onClick={handleClick}
              sx={{
                display: { xs: 'none', sm: 'block' },
                marginLeft: theme.spacing(1.25),
              }}
            >
              <Avatar className={classes.avatar}>
                {getApplicantName(user)[0]}
              </Avatar>
            </Grid>
            {/* } */}
          </Grid>
          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {/* <Typography
              sx={{ p: 2 }}
              onClick={() => {
                onProfile()
              }}
              className={classes.avatar}
            >
              Profile
            </Typography> */}
            <Typography
              sx={{ p: 2 }}
              onClick={() => {
                dispatch({
                  type: 'LOGOUT',
                  payload: {
                    user: {},
                    token: null,
                  },
                });
                navigate('login');
              }}
              className={classes.avatar}
            >
              Logout
            </Typography>
          </Popover>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
