import { BACKEND_BASE_URL, KEY_ACCESS_TOKEN, KEY_CONFIG_URL, KEY_REFRESH_TOKEN } from '../Constants/constant';
import axios from 'axios';

let appConfig;
export let axiosInstance;
const configUrl = KEY_CONFIG_URL;

export const getAxiosInstance = (data) => {
  if (axiosInstance) {
    return axiosInstance;
  }
  appConfig = data[0]?.config;
  axiosInstance = axios.create({
    baseURL: BACKEND_BASE_URL,
    timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(KEY_REFRESH_TOKEN);
    if (refreshToken) {
      req.headers['access-token'] = refreshToken;
    }
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      if (localStorage.refresh_token && (error.response.status === 402 || error.response.status === 502)) {
        localStorage.clear();
        window.location.reload();
        return
      }
      var config = {
        method: 'get',
        baseURL: `${appConfig.tokenUrl}`,
      };
      return axios(config).then(function (response) {
        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access_token);
        error.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
        return axios(error.response.config);
      });
    }
  );
}

export const getConfig = async () => {
  const path = 'apply.turbostart.co';
  const res = await axios.get(`${configUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getAlumniHomePageData = async () => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/homepage&page=PortalHome`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getAlumniCorner = async () => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/alumnicorners`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getAlumniOfTheMonth = async () => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/featuredalumni`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getTopEvents = async () => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/alumnievents`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getAllJobs = async (contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/joblistings&applied=true&alumniid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getJobDetails = async (recordId, contactId, flag = "true") => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/joblistings&applied=${flag}&recordid=${recordId}&alumniid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getAlumniDetails = async (contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/alumni&recordid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getFeaturedAlumniDetails = async (contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/featuredalumni&recordid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getAcademicPrograms = async () => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/academicprograms`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getAllEvents = async () => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/alumnievents`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getSingleEvent = async (recordId, contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/retrieve/alumnievents&recordid=${recordId}&applied=true&alumniid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createDocuments = async (data) => {
  const path = `/salesforce?endpoint=services/apexrest/alumni/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const CreateEvaluationMetric = async (data, contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/EvaluationMetric/${contactId}`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const CreateEvaluationMetricPhase2 = async (data, contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/EvaluationMetricPhase2/${contactId}`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getEvaluationMetric = async (evaluationId) => {
  const path = `/salesforce?endpoint=services/apexrest/EvaluationMetric/${evaluationId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getEvaluationMetricPhase2 = async (evaluationId) => {
  const path = `/salesforce?endpoint=services/apexrest/EvaluationMetricPhase2/${evaluationId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationDataPhase2 = async (contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/GetApplicationDataPhase2Eval/${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationsData = async (contactId) => {
  const path = `/salesforce?endpoint=services/apexrest/evaluation/retrieve/applications?memberid=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationData = async (id, round, memberId) => {
  const path = `/salesforce?endpoint=${encodeURIComponent(`services/apexrest/evaluation/retrieve/application?applicationid=${id}&round=${round}&memberid=${memberId}`)}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postFeedback = async (data) => {
  const path = `/salesforce?endpoint=services/apexrest/evaluation/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getDocument = async (id) => {
  const path = `/salesforce?endpoint=services/apexrest/startup/retrieve/document?docid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};


