import { Divider, useTheme } from '@mui/material';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';

export default function KenTabs({ tabs = [], active = 0, tabHandleChange }) {
  const [value, setValue] = useState(active);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabHandleChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((tab) => (
          <Tab label={tab.label} {...tab.props} value={tab.value} />
        ))}
      </Tabs>
      <Divider sx={{ marginTop: theme.spacing(3) }} />
    </Box>
  );
}
