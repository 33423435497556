import * as React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import './Accordion.css'

const useStyles = makeStyles((theme) => ({
  accordion: {
    fontSize: '13px',
  },
  rootAccordionSummary: {
    // background: 'red',
    // padding: '5px ',
    // paddingBottom: '0px',
    // paddingTop: '0px',
    fontSize: '13px',
    background: 'rgba(242, 95, 41, 0.19)',
    borderRadius: '5px',
    fontFamily: 'poppins',
    color: 'rgba(0, 0, 0, 1)',
    // fontSize:'13px',
    borderBottom: ` 1px solid ${theme.palette.KenColors.grey4}`,
    '&> .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.KenColors.neutral900,
    },
  },
  label: {
    color: theme.palette.KenColors.neutral900,
    textAlign: 'left',
    marginLeft: '5px',
    fontSize: '15px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '100%',
      color: theme.palette.KenColors.neutral900,
    },
  },
  listContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '8px 0 16px 8px',
    },
  },
}));

export default function KenAccordion(props) {
  const { children, label, expanded, handleChange } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <Box margin={isMobileScreen ? '16px' : undefined}>
      <Accordion
        elevation={0}
        expanded={expanded}
        className='Accordion-summary'
        classes={{ root: classes.accordion }}
        onChange={() => {
          handleChange();
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classes.rootAccordionSummary }}
        >
          <Typography
            variant="body2"
            sx={{ marginLeft: '-16px' }}
            className={classes.label}
          >
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.listContainer}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

