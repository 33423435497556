import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import KenInput from '../../../Components/KenInput';
import KenSelect from '../../../Components/KenSelect';
const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: '400',
    fontSize: '13px',
    padding: '1px 4px 4px',
    borderRadius: '5px',
    fontFamily: 'poppins',
    color: 'rgba(0, 0, 0, 1)',
    textTransform: 'capitalize',
    ['@media (max-width: 800px)']: {
      fontSize: '8px',
    },
    ['@media (max-width: 600px)']: {
      fontSize: '8px',
    },
  },
}));
const SportsChild = (props) => {
  const classes = useStyles();
  const errorMessage = 'Field is required.';
  const [sportsAchievements, setSportsAchievements] = useState([]);
  const initialState = {
    name: '',
    nameError: '',
    type: '',
    typeError: '',
    date: '',
    dateError: '',
  };
  const [sportsObject, setSportsObject] = useState(initialState);
  const [open, setOpen] = React.useState(false);
  const handleOnChange = (label, value, errorLabel) => {
    setSportsObject({
      ...sportsObject,
      [label]: value,
      [errorLabel]: value === '' ? errorMessage : '',
    });
  };
  const editData = (label, value, errorLabel, index) => {
    const obj = {
      ...sportsAchievements[index], [label]: value,
      [errorLabel]: value === '' ? errorMessage : ''
    };
    sportsAchievements[index] = { ...obj };
    setSportsAchievements([...sportsAchievements])
  };
  const validateForm = () => {
    if (
      sportsObject.name !== '' &&
      sportsObject.type !== '' &&
      sportsObject.date !== ''
    ) {
      return true;
    } else {
      setSportsObject({
        ...sportsObject,
        nameError: sportsObject.name === '' ? errorMessage : '',
        typeError: sportsObject.type === '' ? errorMessage : '',
        dateError: sportsObject.date === '' ? errorMessage : '',
      });
      return false;
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClickOpen = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
  };
  const handleDelete = () => {
    sportsAchievements.splice(selectedIndex, 1);
    setSportsAchievements([...sportsAchievements]);
    handleClose();
  };
  return (
    <div className='css'>
      <Grid container xs={12}>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{ fontSize: '15px' }} id="alert-dialog-title">
              {"Are you sure?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleDelete}>Yes</Button>
              <Button onClick={handleClose} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {sportsAchievements?.length > 0 &&
          sportsAchievements.map((item, index) => {
            return (
              <>
                <Grid item xs={3} style={{ marginRight: 20, padding: '5px' }}>
                  <KenInput
                    required
                    name="Name Of Achievement"
                    label="Name Of Achievement"
                    placeholder="Enter Name of Achievement"
                    labelClassName={classes.inputLabel}
                    onChange={(e) => {
                      editData('name', e.target.value, 'nameError', index);
                    }}
                    error={item.nameError}
                    value={item.name}
                  />
                </Grid>
                <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
                  <KenInput
                    required
                    name="Type Of Sport"
                    label="Type Of Sport"
                    placeholder="Enter Type"
                    labelClassName={classes.inputLabel}
                    onChange={(e) => {
                      editData('type', e.target.value, 'typeError', index);
                    }}
                    error={item.typeError}
                    value={item.type}
                  />
                </Grid>
                <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
                  <KenSelect
                    name="program"
                    required
                    label="Date Recieved"
                    placeholder="Select"
                    labelClassName={classes.inputLabel}
                    options={[
                      { label: 'react', value: 1 },
                      { label: 'c++', value: 2 },
                    ]}
                    handleChange={(e) => {
                      editData('date', e.target.value, 'dateError', index);
                    }}
                    error={item.dateError}
                    value={item.date}
                  />
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    handleClickOpen(index);
                  }}
                ><DeleteIcon style={{ cursor: "pointer", width: '20px', height: '20px', marginBottom: '-26px' }} />
                </div>
              </>
            );
          })}
        <>
          <Grid item xs={3} style={{ marginRight: 20, padding: '5px' }}>
            <KenInput
              required
              name="Name Of Achievement"
              label="Name Of Achievement"
              placeholder="Enter Name of Achievement"
              labelClassName={classes.inputLabel}
              onChange={(e) => {
                handleOnChange('name', e.target.value, 'nameError');
              }}
              error={sportsObject.nameError}
              value={sportsObject.name}
            />
          </Grid>
          <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
            <KenInput
              required
              name="Type Of Sport"
              label="Type Of Sport"
              placeholder="Enter Type"
              labelClassName={classes.inputLabel}
              onChange={(e) => {
                handleOnChange('type', e.target.value, 'typeError');
              }}
              error={sportsObject.typeError}
              value={sportsObject.type}
            />
          </Grid>
          <Grid item xs={2.5} style={{ marginRight: 20, padding: '5px' }}>
            <KenSelect
              name="program"
              required
              label="Date Recieved"
              placeholder="Select"
              labelClassName={classes.inputLabel}
              options={[
                { label: 'react', value: 1 },
                { label: 'c++', value: 2 },
              ]}
              handleChange={(e) => {
                handleOnChange('date', e.target.value, 'dateError');
              }}
              error={sportsObject.dateError}
              value={sportsObject.date}
            />
          </Grid>
        </>
        <Grid item xs={12}>
          <Box>
            <div style={{ display: 'flex' }}>
              <Link
                component="button"
                variant="body2"
                underline="none"
                style={{ marginTop: '10px', color: '#000000', fontSize: '13px' }}
                onClick={() => {
                  if (validateForm()) {
                    setSportsAchievements([
                      ...sportsAchievements,
                      { ...sportsObject },
                    ]);
                    setSportsObject({ ...initialState });
                  }
                }}
              >
                + Add Sports Achievement
              </Link>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default SportsChild;
