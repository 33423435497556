import { useMediaQuery, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { CgFileDocument } from "react-icons/cg";
import { FiArrowUpRight } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import TransoLogo from "../../Assets/TRANSO_logo.png";
import KennovateLogo from "../../Assets/kennovate-Logo.png";
import PiLogo from "../../Assets/pi-Logo.png";
import { useEffect } from "react";
import { getApplicationsData } from "../../Utils/apiServices";
import { useContext } from "react";
import { AuthContext } from "../../Context/authContext";
import moment from "moment";

const Header = React.lazy(() => import("../Header/index"));

const DashBoard = () => {
  const root = document.querySelector(':root');
  const primary = getComputedStyle(root).getPropertyValue('--appTheme')
  const shadeBlack = getComputedStyle(root).getPropertyValue('--appTheme-shade')

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const {
    state: { user },
  } = useContext(AuthContext);


  const handleModalClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getApplicationsData(user?.Id).then(res => {
      console.log(res)
      const meetingData = []
      setData(res?.data)
      res.data.forEach(el => {
        el?.Meeting__r?.records?.forEach(elem => {
          console.log(elem, moment(elem?.Start_Date_Time__c).isAfter(moment()))
          if (moment(elem?.Start_Date_Time__c).isAfter(moment().subtract(1, 'days')))
            meetingData.push({ ...elem, Application__c: el.Application__c, Review_Round__c: el.Review_Round__c, PanelId: el?.Id })
        })
      })
      setMeetingData(meetingData)
    }).catch(err => {
      console.log(err)
    })
  }, [])

  console.log(meetingData)

  const [subProfile, setSubProfile] = useState({
    title: "Startups",
    startups: [
      {
        Name: "PiChain",
        Type: "Information Technology",
        status: "pending",
        logo: PiLogo,
      },
      {
        Name: "Transo",
        Type: "Logistics",
        status: "Evaluated",
        logo: TransoLogo,
      },
      {
        Name: "Kennovate",
        Type: "Enterprise SaaS",
        status: "pending",
        logo: KennovateLogo,
      },
    ],
  });

  const scheduleContent = [
    {
      title: "IC Call Day 1",
      MeetingTime: "June 23, 2023 2pm - 3pm(IST-Kolkata)",
      category: 'startup',
      startUps: [
        {
          Name: "PiChain",
          Type: "Information Technology",
          status: "pending",
          logo: PiLogo,
        },
        {
          Name: "Transo",
          Type: "Logistics",
          status: "Evaluated",
          logo: TransoLogo,
        },
        {
          Name: "Kennovate",
          Type: "Enterprise SaaS",
          status: "pending",
          logo: KennovateLogo,
        },
      ],
    },
  ];

  function getFirstLetters(inputString) {
    const words = inputString?.split(' '); // Split the input string into words
    const firstLetters = words?.map(word => word?.charAt(0)); // Extract the first letter from each word
    return firstLetters?.join(''); // Join the first letters back into a single string
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => {
          handleModalClose();
        }}
      >
        <Box component={"div"} width={isMobileScreen ? "100%" : "60%"} height={'70vh'} overflow="scroll" className={`py-4 px-4 border-0 rounded-2 common-modal `} bgcolor={primary}>
          <div className="d-flex justify-content-between pt-1 pb-3">
            <h6 className="font-primary-16-bold m-0 p-0">Scoring Criteria</h6>
            <IconButton
              onClick={() => {
                handleModalClose();
              }}
            >
              <RxCross1 color="white" size={"16px"} />
            </IconButton>
          </div>
          <li className="font-white-14-normal lh-base">Market Opportunity (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Limited market potential with niche appeal.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Moderate market potential, but with significant limitations.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Promising market opportunity with some competition.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Attractive market opportunity with room for growth.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Exceptional market opportunity with clear demand and growth potential.</ul>
          <li className="font-white-14-normal lh-base mt-4">Team Strength (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Inexperienced or inadequately skilled team.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Limited relevant experience, with gaps in key roles.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Competent team with relevant expertise.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Experienced and well-rounded team with a track record.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Exceptional team with a strong track record and complementary skills.</ul>
          <li className="font-white-14-normal lh-base mt-4">Product/Technology Innovation (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Mimic or minimal innovation.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Incremental improvements on existing solutions.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Some innovation, but not groundbreaking.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Innovative product/technology with unique features.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Highly innovative, disruptive, and game-changing product/technology.</ul>
          <li className="font-white-14-normal lh-base mt-4">Traction and Milestones (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Limited user adoption or minimal progress.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Some early traction, but far from milestones.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Steady growth and achieving key milestones.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Strong traction, clear product-market fit, and milestones met.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Rapid growth, impressive user adoption, and exceeding milestones.</ul>
          <li className="font-white-14-normal lh-base mt-4">Business Model and Monetization (1-5)</li>
          <ul className="font-white-14-normal lh-base m-1"> 1 - Unclear or unsustainable business model.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 2 - Basic monetization strategy with uncertainties.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 3 - Viable business model with potential for improvement.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 4 - Strong monetization strategy and sustainable revenue streams.</ul>
          <ul className="font-white-14-normal lh-base m-1"> 5 - Highly scalable and profitable business model with a clear path to revenue growth.</ul>
          {/* <h6 className="font-white-14-normal lh-base mt-3 mb-3">
            Please note that judges are requested to assign a score of between 1 to 5 points for each criterion, with 1 indicating the lowest score and 5 indicating the highest score. This scoring mechanism will assist in a comprehensive evaluation of the participating startups.          </h6> */}
        </Box>
      </Modal>
      <Header />
      {/*DashBoard header section */}
      <div className="PageContainer">
        <Box component={'div'} bgcolor={shadeBlack} className="card rounded-3 shadow-sm border-0 p-4">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="row">
                <div className="col-2">
                  <Avatar sx={{ bgcolor: "#07588F", width: 56, height: 56 }}>{user?.Name && user?.Name[0]}</Avatar>
                </div>
                <div className="col-10 px-4">
                  <h2 className="font-white-24-bold text-capitalize">Hey {user?.Name} !</h2>
                  <Box component={'h2'} fontSize={'18px'} color={'#7f7f81'} fontWeight={'500'} className="font-white-18-bold">Welcome to the Jury Portal</Box>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-7">
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <h2 className="font-primary-16-bold text-nowrap">
                        Upcoming Sessions&nbsp;
                        <FiArrowUpRight />
                      </h2>
                      <h2 className="font-white-18-bold">{meetingData?.length}</h2>
                    </div>
                    <div className="col-sm-6">
                      <h2 className="font-primary-16-bold text-nowrap">
                        Startups&nbsp;
                        <FiArrowUpRight />
                      </h2>
                      <h2 className="font-white-18-bold">{data?.length}</h2>
                    </div>
                  </div>
                </div>
                <Box component={'div'} color={'#A3AAD0'} fontSize={'16px'} className="col-5  text-end cursor-pointer">
                  <p className="mt-3" onClick={() => { handleModalClose(); }} >
                    <CgFileDocument />
                    &nbsp;Scoring Criteria
                  </p>
                </Box>
              </div>
            </div>
          </div>
        </Box>
        <div className="mt-4 h-100">
          <Grid container spacing={4} height={"520px"}>
            <Grid item className="w-100 h-100" md={6}>
              <Box component={"div"} padding={"24px"} paddingBottom={"0px"} className="card border-0 shadow-sm rounded-3 h-100 hoverborder" bgcolor={shadeBlack} >
                <h2 className="font-white-18-bold">Your Schedule</h2>
                {/* <h2 className="font-white-14-bold">(23 June, Sunday)</h2> */}
                <Box component={"div"} marginTop={"20px"} overflow={"scroll"} height={"65%"}>
                  {meetingData.slice() // Create a shallow copy of the array to avoid modifying the original array
                    .sort((a, b) => {
                      // Compare the Start_Date_Time__c properties for sorting
                      const startTimeA = moment(a.Start_Date_Time__c);
                      const startTimeB = moment(b.Start_Date_Time__c);
                      return startTimeA - startTimeB;
                    }).map((item, index) => (
                      <Box component={"div"} key={index} className="mt-2 cursor-pointer p-3 rounded-1" bgcolor="#25272D" height={"132px"} border={subProfile.title === item.title ? "2px solid #3e3f43" : ""} onClick={() => setSubProfile({ title: item.title, startups: item.startUps })} >
                        <h2 className="font-white-16-bold">{item.Name}</h2>
                        <h2 className="font-grey-14-bold mt-1">
                          Meeting Details: <b className="font-white-14-normal">{`${item?.Start_Date_Time__c && moment(item?.Start_Date_Time__c).format('MMM DD, YYYY hh:mma')} ${item?.End_Date_Time__c && `- ${moment(item?.End_Date_Time__c).format('hh:mma')}`}`} IST</b>
                        </h2>
                        <Box component={"div"} marginTop={"20px"}>
                          <Box component={'button'} color={"#000000"} fontWeight={'600'} className="primary-contained-button px-3 py-1" onClick={() => { window.open(item?.Meeting_Link__c, "_blank") }}>Join</Box>
                          {/* <button className="font-primary-14-bold border-0 bg-transparent" onClick={() => { navigate("/startup-profile", { state: { Application__c: item?.Application__c, Review_Round__c: item?.Review_Round__c, PanelId: item?.PanelId } }); }}>&nbsp;&nbsp;View Startups {`>>`}</button> */}
                        </Box>
                      </Box>
                    ))}
                </Box>
                {scheduleContent.length > 3 && (
                  <div className="text-center py-2">
                    <Box component={'div'} color={'white'} fontSize={'16px'} className=" border-0 bg-transparent">Show more</Box>
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item className="w-100 h-100" md={6}>
              <Box component={"div"} paddingBottom={"0px"} paddingTop={"24px"} className="card border-0 shadow-sm rounded-3 h-100" bgcolor={shadeBlack}>
                <h2 className="font-white-18-bold mb-4 px-4">{subProfile.title}</h2>
                <Box component={"div"} overflow={"scroll"} height={"68vh"}>
                  {data?.map((item, index) => {
                    console.log(item.Is_Review_Complete__c, "6767i67");
                    let name = getFirstLetters(item?.Application__r?.Startup_Name__c)
                    return <Box component={"div"} key={index} className="d-flex justify-content-between px-3 w-100 my-4 pb-3 border-bottom-startups" onClick={() => { navigate("/startup-profile", { state: { Application__c: item?.Application__c, Review_Round__c: item?.Review_Round__c, PanelId: item?.Id } }); }} >
                      <div className="d-flex gap-3">
                        <Avatar sx={{ bgcolor: "#07588F", width: 56, height: 56, textTransform: 'uppercase' }}>{name}</Avatar>
                        <div>
                          <h2 className="font-white-20-bold cursor-pointer">{item?.Application__r?.Startup_Name__c}</h2>
                          <h2 className="font-grey-14-bold">{item?.Application__r?.Sector__c}</h2>
                        </div>
                      </div>
                      <div>
                        <div className={item.Is_Review_Complete__c === false && !item.Evaluation_Scores__r ? "review-status-button py-1 rounded-4 text-uppercase px-2" : "done-status-button rounded-4 text-uppercase px-2 py-1"}>{item.Evaluation_Scores__r ? 'Evaluated' : 'Pending'}</div>
                      </div>
                    </Box>
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashBoard;
