import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import KenInput from '../../../Components/KenInput';
import KenSelect from '../../../Components/KenSelect';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: '400',
    fontSize: '13px',
    padding: '1px 4px 4px',
    borderRadius: '5px',

    fontFamily: 'poppins',
    color: 'rgba(0, 0, 0, 1)',

    textTransform: 'capitalize',
    ['@media (max-width: 800px)']: {
      fontSize: '9px',
    },['@media (max-width: 600px)']: {
      fontSize: '9px',
    },
  },
}));

const ParentsChild = (props) => {
  const classes = useStyles();
  const errorMessage = 'Field is required.';
  const [parent, setParent] = useState([]);
  const initialState = {
    name: '',
    nameError: '',
    type: '',
    typeError: '',
    date: '',
    dateError: '',
  };

  const [parentObject, setParentObject] = useState(initialState);
  const [open, setOpen] = React.useState(false);
  const handleOnChange = (label, value, errorLabel) => {
    setParentObject({
      ...parentObject,
      [label]: value,
      [errorLabel]: value === '' ? errorMessage : '',
    });
  };

  const editData = (label, value, errorLabel, index) => {
    const obj = {
      ...parent[index], [label]: value,
      [errorLabel]: value === '' ? errorMessage : ''
    };
    parent[index] = { ...obj };
    setParent([...parent])
  };

  const validateForm = () => {
    if (
      parentObject.name !== '' &&
      parentObject.type !== '' &&
      parentObject.date !== ''
    ) {
      return true;
    } else {
      setParentObject({
        ...parentObject,
        nameError: parentObject.name === '' ? errorMessage : '',
        typeError: parentObject.type === '' ? errorMessage : '',
        dateError: parentObject.date === '' ? errorMessage : '',
      });
      return false;
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClickOpen = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
  };
  const handleDelete = () => {
    parent.splice(selectedIndex, 1);
    setParent([...parent]);
    handleClose();
  };
  return (
    <Grid container xs={12}>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontSize: '15px' }} id="alert-dialog-title">
            {"Are you sure?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {parent?.length > 0 &&
        parent.map((item, index) => {
          return (
            <>
              <Grid item xs={3} style={{marginRight: 20, padding: '5px' }}>
                <KenInput
                  required
                  name="Name"
                  label="Name"
                  placeholder="Enter Name"
                  labelClassName={classes.inputLabel}
                  onChange={(e) => {
                    editData('name', e.target.value, 'nameError', index);
                  }}
                  error={item.nameError}
                  value={item.name}
                />
              </Grid>
              <Grid item xs={2.5} style={{marginRight: 20, padding: '5px' }}>
                <KenInput
                  required
                  name="Type"
                  label="Type"
                  placeholder="Enter Type"
                  labelClassName={classes.inputLabel}
                  onChange={(e) => {
                    editData('type', e.target.value, 'typeError', index);
                  }}
                  error={item.typeError}
                  value={item.type}
                />
              </Grid>
              <Grid item xs={2.5} style={{marginRight: 20, padding: '5px' }}>
                <KenSelect
                  name="program"
                  required
                  label="Date Recieved"
                  placeholder="Select"
                  labelClassName={classes.inputLabel}
                  options={[
                    { label: 'react', value: 1 },
                    { label: 'c++', value: 2 },
                  ]}
                  handleChange={(e) => {
                    editData('date', e.target.value, 'dateError', index);
                  }}
                  error={item.dateError}
                  value={item.date}
                />
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  handleClickOpen(index);
                }}
              ><DeleteIcon style={{ cursor:"pointer",width: '20px', height: '20px',marginBottom:'-26px' }} />
              </div>
            </>
          );
        })}
      <>
        <Grid item xs={3} style={{ marginRight: 20 ,padding:'5px'}}>
          <KenInput
            required
            name="Name"
            label="Name"
            placeholder="Enter Name"
            labelClassName={classes.inputLabel}
            onChange={(e) => {
              handleOnChange('name', e.target.value, 'nameError');
            }}
            error={parentObject.nameError}
            value={parentObject.name}
          />
        </Grid>
        <Grid item xs={2.5} style={{ marginRight: 20 ,padding:'5px'}}>
          <KenInput
            // style={{ width: '50%' }}
            required
            name="Type"
            label="Type"
            placeholder="Enter Type"
            labelClassName={classes.inputLabel}
            onChange={(e) => {
              handleOnChange('type', e.target.value, 'typeError');
            }}
            error={parentObject.typeError}
            value={parentObject.type}
          />
        </Grid>
        <Grid item xs={2.5} style={{ marginRight: 20 ,padding:'5px'}}>
          <KenSelect
            name="program"
            required
            label="Date Recieved"
            placeholder="Select"
            labelClassName={classes.inputLabel}
            options={[
              { label: 'react', value: 1 },
              { label: 'c++', value: 2 },
            ]}
            handleChange={(e) => {
              handleOnChange('date', e.target.value, 'dateError');
            }}
            error={parentObject.dateError}
            value={parentObject.date}
          />
        </Grid>
      </>
      <Grid item xs={12}>
        <Box>
          <div style={{ display: 'flex' }}>
            <Link
              component="button"
              variant="body2"
              underline="none"
              style={{ marginTop: '10px', color: '#000000',fontSize:'13px' }}
              onClick={() => {
                if (validateForm()) {
                  setParent([
                    ...parent,
                    { ...parentObject },
                  ]);
                  setParentObject({ ...initialState });
                }
              }}
            >
            + Add Patent/Publication
            </Link>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
export default ParentsChild;