import React, { useContext } from 'react';

import { AuthContext } from '../../Context/authContext';
import { Navigate } from 'react-router-dom';
import routes from '../../Constants/routes';

function KenPrivateRoute({ children }) {
  const {
    state: { isAuthenticated },
  } = useContext(AuthContext);

  return isAuthenticated ? children : <Navigate to={routes.index} />;
}

export default KenPrivateRoute;
