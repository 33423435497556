import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { SubTitle, Title } from '.';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Countdown from 'react-countdown';
import KenButton from '../../../Components/KenButton';
import OtpInput from 'react-otp-input';
import { activeTheme } from '../../../Utils/Themes/themeHelper';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    // minWidth: '500px',
    // maxWidth: '500px',
    // minHeight: '520px',
    'box-shadow':
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '16px',
    // padding: '48px 84px',
    //   ['@media (max-width: 800px)']: {
    //     minWidth: '484px !important',
    // maxWidth: '484px',
    // minHeight: '520px',
    //   },
    //   ['@media (max-width: 600px)']: {
    //     minWidth: '484px !important',
    // maxWidth: '300px',
    // minHeight: '300px',
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     boxShadow: 'none',
    //     padding: '8px',
    //     minWidth: '360px',
    //     maxWidth: '360px',

    //   },
  },
  title: {
    color: 'white',
    '@media (max-width: 600px)': {
      fontSize: '30px'
    }

  },
  subTitle: {
    marginBottom: '16px',
    color: 'white',
    textAlign: 'left',
    'line-height': '150% !important',
  },
  mobileLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    'text-transform': 'capitalize',
    color: '#505F79',
  },
  headerContent: {
    display: "flex",
    width: "100%",
    gap: "20px",
    // "justifyContent": "space-between",
    "alignItems": "center",
    margin: "20px 0px",
    [theme.breakpoints.down('sm')]: {
      margin: "0px 0px 20px 0px",
    },
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
  containerStyle: {
    color: 'red',
    width: '100%',
    '& div': {
      width: '70px',
      border: '0',
      borderBottom: '1px solid #A8AFBC;',
      margin: '16px 8px',
      '@media (max-width: 600px)': {
        marginLeft: '25px !important'
      },
    },
  },
  inputStyle: {
    width: '100% !important',
    border: '0',
    height: '35px',

    '&:focus-visible': {
      outline: 'none',
    },
  },
  resend: {
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: '#52C15A',
  },
}));

let COOL_DOWN_COUNT_IN_SECONDS = 2 * 60;
function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function Verify(props) {
  const classes = useStyles();
  const navigate = useNavigate()

  const [otp, setOtp] = useState('');
  const [countDown, setCountDown] = useState(
    Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 1000
  );
  const countDownRef = useRef();
  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (countDownRef) {
      countDownRef.current.api.start();
    }
  }, [countDown]);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Button
          className={classes.resend}
          onClick={(e) => {
            e.preventDefault();
            if (typeof props.resendOTP === 'function') {
              props.resendOTP();
            }
            setCountDown(Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 1000);
          }}
        >
          Resend
        </Button>
      );
    } else {
      // Render a countdown
      return (
        <>
          <Box>
            <span style={{ color: "#fff" }}>
              {pad(minutes, 2)}:{pad(seconds, 2)}
            </span>
          </Box>
        </>
      );
    }
  };

  return (
    <Box
      className={classes.contentWrapper}
      display={'flex'}
      flexDirection="column"
      justifyContent={'flex-start'}
      alignItems="flex-start"
    >
      <Box className={classes.headerContent}>
        <IconButton
          onClick={() => {
            navigate(-1, { replace: true });
          }}
        >
          <ArrowBackIcon sx={{ color: "white" }} />
        </IconButton>
        <Title className={classes.title} text={props.title} />
      </Box>
      <SubTitle className={classes.subTitle} text={props.subTitle} />
      {props.children}
      <OtpInput
        isInputNum
        value={otp}
        onChange={handleChange}
        numInputs={4}
        containerStyle={classes.containerStyle}
        inputStyle={classes.inputStyle}
      />
      <Box
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        my={4}
        width="100%"
      >
        <Countdown
          ref={countDownRef}
          date={countDown}
          renderer={renderer}
        ></Countdown>
      </Box>
      <KenButton
        variant="primary"
        buttonClass={classes.verify}
        type="submit"
        disabled={props.loading || otp.length < 4}
        onClick={(e) => {
          e.preventDefault();
          if (`${otp}`.length === 4) {
            props.onVerify({ otp });
          }
        }}
      >
        {props.submitText ? props.submitText : 'Verify'}
      </KenButton>
      {props.loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '49%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </Box>
  );
}
