import React, { useContext, useCallback, useEffect, useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Badge, Box, Button, Fab, Grid, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import ProfileImage from '../../Assets/Images/profile_image.png';
import facebookSVG from '../../Assets/Svg/facebook.svg';
import linkedinSVG from '../../Assets/Svg/linkedin.svg';
import KenLoader from '../../Components/KenLoader';
import { AuthContext } from '../../Context/authContext';
import { activeTheme } from '../../Utils/Themes/themeHelper';
import { Achievements } from './Components/Achievements';
import { Cases } from './Components/Cases';
import { PersonalDetails } from './Components/PersonalDetails';
import { getAlumniDetails } from '../../Utils/apiServices';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  applyNowBtn: {
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    borderRadius: '32px',
    boxShadow:
      '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      cursor: 'pointer',
      background: '#fff',
      color: '#FDB813',
      boxShadow:
        '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
      border: '1px solid #FDB813',
    },
    minWidth: '8vw',
    padding: '5px 10px',
    marginTop: '10px',
  },
  topBox: {
    height: 51,
    background: '#fff',
    borderRadius: '15px',
  },
  myProfileTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '150%',
    textAlign: 'left',
    color: activeTheme.general.myProfileTitleText,
    paddingTop: '12px',
    paddingLeft: '20px',
  },
  userNameTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'Normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: '#000000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    marginLeft: '20px',
  },
  subsTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '150%',
    textAlign: 'left',
    letterSpacing: '0.1rem',
    color: '#000000',
    textTransform: 'capitalize',
    paddingBottom: '20px',
  },
  perTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'left',
    // color: '##27AE60',
    textTransform: 'capitalize',
    letterSpacing: '0.1rem',
    paddingTop: '10px',
  },
  TabTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#03248F',
  },
  Tab: {
    background: '#fff',
  },
  input: {
    background: '#fff !important',
  },
  progressBar: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: activeTheme.general.barColor,
    },
  },
}));

// const progressStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'start',
//   color: '#27AE60',
//   // color: row > 0 ? '#27AE60' : '#EF4060',
// };

const ProfileDetails = () => {
  const classes = useStyles();
  const [profileUpdate, setProfileUpdate] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const [alumniData, setAlumniDetails] = useState({});
  const [printProfileImg, setProfileImg] = useState();
  const [loading, setLoading] = React.useState(false);
  const {
    state: { user }
  } = useContext(AuthContext);

  const onDrop = useCallback(async (acceptedFiles, getInputProps, props) => {
    const formData = new FormData();
    const [file] = acceptedFiles;
    let base64;
    const pdf = acceptedFiles.find(f => f)
    let reader = new FileReader()
    reader.readAsDataURL(pdf);
    reader.onload = () => {
      base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      const myArray = file.name.split(".");
      const body = {
        fileName: file.name,
        title: myArray[0],
        formattedDocumentName: `${myArray[0]}_${user?.alumniName}.${myArray[myArray.length - 1]}`,
        expiryDate: null,
        body: base64,
        // parentId: getRecordedId,
        fileType: file.type,
      };
      setProfileImg(body);
      console.log(body);
      /*createDocuments({ attachments: [body] })
        .then((res) => {
          if (res?.success === true) {
            setApplyBtn(true);
            // setUploadDisplay(false);
            setUploadResume(false);
            // return enqueueSnackbar("Resume Uploaded Successfully", {
            //   variant: "success",
            // });
          }
        })
        .catch((err) => {
          return enqueueSnackbar(
            "Error while uploading. Please try again later.",
            {
              variant: "error",
            }
          );
        });*/
    }
  }, []);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    onDrop
  });

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path}
      {/* - {file.size} bytes */}
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <>
      {/* <li key={file.path}>
        {file.path}
        - {file.size} bytes
      </li> */}
      {errors && <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>}
    </>
  ));
  useEffect(() => {
    setLoading(true);
    getAlumniDetails(user.id)
      .then((res) => {
        console.log(res.data)
        setAlumniDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const profileForm = useFormik({
    initialValues: {
      aboutMe: alumniData.firstName
    },
    validationSchema: Yup.object({
      aboutMe: Yup.string().max(5, "Must be 255 character or less").required("Required")
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });

  return (
    <Box style={{ marginLeft: '-40px' }}>
      {loading && <KenLoader />}
      <Box xs={12}>
        <Grid className={classes.topBox} container spacing={1}>
          <Grid xs={10}>
            <Typography className={classes.myProfileTitle}>
              MY PROFILE
            </Typography>

          </Grid>
          <Grid xs={2}>
            {profileUpdate ? (
              <Button
                size="small"
                variant="contained"
                className={classes.applyNowBtn}
                onClick={() => setProfileUpdate(false)}
              >
                Save
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                className={classes.applyNowBtn}
                onClick={() => setProfileUpdate(true)}
              >
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box xs={12}>
        <Grid style={{ marginTop: '45px' }} container xs={12} spacing={1}>
          <Grid xs={3}>
            {profileUpdate ? (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <label htmlFor="image">
                    <input
                      style={{ display: 'none' }}
                      id="image"
                      name="image"
                      type="file"
                    />

                    <Fab
                      size="small"
                      component="span"
                      aria-label="add"
                      style={{
                        backgroundColor: '#D7DEE9',
                        position: 'inherit',
                        marginLeft: '65px',
                        marginTop: '-317px',
                        color: '#505F79',
                      }}
                    >

                      <CreateOutlinedIcon {...getRootProps({ className: 'dropzone' })} />
                      <input {...getInputProps()} />
                    </Fab>
                  </label>

                }
              >
                {/* <h4>Accepted files</h4>
                <ul>{acceptedFileItems}</ul>
                <h4>Rejected files</h4>
                <ul>{fileRejectionItems}</ul> */}
                {printProfileImg ? <img alt="Remy Sharp" width="259" src={`data:image/png;base64,` + printProfileImg.body} />
                  : <img alt="Remy Sharp" width="259" src={user?.photoUrl || ProfileImage} />
                }
              </Badge>
            ) : (
              <img alt="Remy Sharp" width="259" src={user?.photoUrl || ProfileImage} />
            )}
          </Grid>
          <Grid xs={7} style={{['@media (max-width: 800px)']: {
      marginLeft:'90px !important'
    },}}>
            {profileUpdate ?
              <>
                <form onSubmit={profileForm.handleSubmit}>
                  <div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    <Typography className={classes.userNameTitle} style={{ display: 'flex', flexDirection: 'row', alignItems: "center", marginLeft: '20px' }}>
                      {user?.alumniName}
                      <img alt={user?.alumniName} width={40} style={{
                        marginLeft: '20px',
                        padding: '7px',
                        background: '#fff',
                        borderRadius: '50%',
                      }}
                        src={facebookSVG}
                      />
                      <img
                        alt="Remy Sharp"
                        width={40}
                        style={{
                          marginLeft: '20px',
                          // marginTop: '18px',
                          padding: '7px',
                          background: '#fff',
                          borderRadius: '50%',
                        }}
                        src={linkedinSVG}
                      /></Typography>
                  </div>
                  <Typography style={{
                    textAlign: 'left',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#DE4F46',
                    marginLeft: '20px',
                    ['@media (max-width: 800px)']: {
                      marginLeft: '108px !important',
                    },
                  }}>
                    Founder
                  </Typography>
                  <Typography style={{ marginLeft: '20px', textAlign: 'left', ['@media (max-width: 800px)']: {
      marginLeft: '106px'
    }, }}>
                    Entrepreneur First Pvt. Ltd.
                  </Typography>
                  <br />
                  <Typography style={{ marginLeft: '20px', textAlign: 'left', fontSize: '13px', ['@media (max-width: 800px)']: {
      marginLeft: '105px'
    }, }}>
                    About Me
                  </Typography>
                  <TextField style={{ margin: '5px 0px 0px 20px', textAlign: 'left', borderRadius: '4px', width: '100%', height: '29px !important', backgroundColor: '#fff', fontSize: '13px', padding: '0px !important' }}
                    multiline
                    id="aboutMe"
                    name="aboutMe"
                    onChange={profileForm.handleChange}
                    onBlur={profileForm.handleBlur}
                    value={profileForm.values.aboutMe}
                    error={profileForm.errors.email}
                    rows={1}
                  />
                  {profileForm.errors.aboutMe && profileForm.touched.aboutMe ?
                    <>
                      {profileForm.errors.aboutMe}
                    </>
                    : null}
                  <button type='submit'>save</button>
                  </div>
                </form>
              </>
              :
              <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                  <Typography className={classes.userNameTitle} >{user?.alumniName} <img
                    alt="Remy Sharp"
                    width={40}
                    style={{
                      marginLeft: '20px',
                      // marginTop: '18px',
                      padding: '7px',
                      background: '#fff',
                      borderRadius: '50%',
                    }}
                    src={facebookSVG}
                  />
                    <img
                      alt="Remy Sharp"
                      width={40}
                      style={{
                        marginLeft: '20px',
                        // marginTop: '18px',
                        padding: '7px',
                        background: '#fff',
                        borderRadius: '50%',
                      }}
                      src={linkedinSVG}
                    /></Typography>
                </div>
                <Typography style={{
                  textAlign: 'left',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#DE4F46',
                  marginLeft: '20px',
                  ['@media (max-width: 800px)']: {
                    marginLeft: '106px !important'
                  },
                }}>
                  Founder
                </Typography>
                <Typography style={{ marginLeft: '20px', textAlign: 'left' }}>
                  Entrepreneur First Pvt. Ltd.
                </Typography>
                <br />

                <Typography style={{ marginLeft: '20px', textAlign: 'left', fontSize: '13px' }}>
                  About Me
                </Typography>
                <Typography style={{ marginLeft: '20px', textAlign: 'left', fontSize: '12px', ['@media (max-width: 800px)']: {
      marginLeft: '106px'
    }, }}>
                  {alumniData.firstName}
                </Typography>
              </>
            }
            {/* <Box style={progressStyle}>
              <LinearProgress
                variant="determinate"
                value={19}
                style={{
                  width: '297px',
                  color: activeTheme.general.progressColor,
                }}
                className={classes.progressBar}
              />
              <Typography
                variant="span"
                className={classes.perTitle}
              // style={{ alignItems: 'start' }}
              >
                {20}%
              </Typography>
            </Box> */}
          </Grid>
        </Grid>
      </Box >

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                className={classes.TabTitle}
                label="PERSONAL DETAILS"
                value="1"
              />
              <Tab
                className={classes.TabTitle}
                label="ACHIEVEMENTS"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel className={classes.Tab} value="1">
            <PersonalDetails />
          </TabPanel>
          <TabPanel className={classes.Tab} value="2">
            <Achievements />
          </TabPanel>

          <TabPanel className={classes.Tab} value="4">
            <Cases />
          </TabPanel>
        </TabContext>
      </Box>
    </Box >
  );
};

export default ProfileDetails;
