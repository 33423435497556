import * as React from 'react';

import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '&>:nth-child(1)': {
      backgroundColor: '#F3F5F7',

      padding: 0,
    },
  },
  input: {
    borderRadius: '4px',
    padding: '10px',
    backgroundColor: '#F3F5F7',
    color: '#505F79',
    textAlign: 'left',
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
  error: {
    backgroundColor: '#FFFFFF',
  },
  helperText: {
    marginLeft: 0,
    fontSize: '12px',
    color: '#FC2C32',
  },
});

const StyledTextField = styled(TextField)(({ error, border }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F3F5F7',
      border: border,
    },
    '&:hover fieldset': {
      borderColor: error ? '#FC2C32' : 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? '#FC2C32' : '#092682',
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#FC2C32',
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline':
  {
    borderColor: '#FC2C32',
  },
  '& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root': {
    backgroundColor: '#F3F5F7',
  },
}));

export default function KenTextField(props) {
  const {
    FormHelperTextProps,
    InputLabelProps,
    inputProps,
    InputProps,
    className,
    error,
    errorLabel,
    children,
    onChange,
    value,
  } = props;

  const classes = useStyles();

  return (
    <>
      <StyledTextField
        value={value}
        onChange={(e) => onChange(e)}
        className={`${className}`}
        classes={{ root: classes.root }}
        InputLabelProps={{ shrink: false, ...InputLabelProps }}
        inputProps={{
          className: error
            ? `${classes.input} ${classes.error}`
            : classes.input,
          ...inputProps,
        }}
        InputProps={{ components: 'p', ...InputProps }}
        FormHelperTextProps={{
          className: classes.helperText,
          ...FormHelperTextProps,
        }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: '300px',
                marginTop: '8px',
                boxSizing: 'border-box',
                borderTop: '8px solid #ffffff',
                borderBottom: '8px solid #ffffff',
              },
            },
          },
        }}
        {...props}
        label=""
        error={
          errorLabel
            ? errorLabel
            : typeof error === 'string'
              ? error
              : error?.message
        }
        helperText={
          errorLabel ? null : typeof error === 'string' ? error : error?.message
        }
      >
        {children}
      </StyledTextField>
    </>
  );
}
