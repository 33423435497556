import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from "../../Context/authContext";
import Briefcase from '../../Assets/Svg/job/briefcase.svg';
import Clock from '../../Assets/Svg/job/clock.svg';
import Google from '../../Assets/Svg/job/google.svg';
import HeaderBG from '../../Assets/Svg/job/header-bg.png';
import India from '../../Assets/Svg/job/india-flag.svg';
import KenButton from '../../Components/KenButton';
import KenLoader from '../../Components/KenLoader';
import Location from '../../Assets/Svg/job/location.svg';
import Salary from '../../Assets/Svg/job/salary.svg';
import UploadModal from './UploadModal';
import { activeTheme } from '../../Utils/Themes/themeHelper';
import { getJobDetails } from '../../Utils/apiServices';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useParams } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    'border-radius': '10px',
    marginLeft: '-40px',
    padding: '0 0 22px 35px',
    'border-top-left-radius': 0,
  },
  header: {
    background:
      'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.94) 41.67%, rgba(255, 255, 255, 0) 100%)',
    marginBottom: theme.spacing(6),
    backgroundImage: `url(${HeaderBG})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 165,
    alignItems: 'center',
    ['@media (max-width: 800px)']: {
      backgroundImage:'none',
    },
    ['@media (max-width: 600px)']: {
      backgroundImage:'none',
    },
  },
  companyLogo: {
    height: 106,
    width: 104,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.1)',
    'border-radius': '6px',
    padding: '10px',
    marginRight: theme.spacing(4),
    '& img': {
      height: 'auto',
      width: '100%'
    },
  },
  jobLogo: {
    width: 94,
  },
  company: {
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '18px',
    'line-height': '25px',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: '#000000',
  },
  country: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '25px',
    display: 'flex',
    'align-items': 'center',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: '#505F79',
  },
  employer: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '16px',
    'line-height': '25px',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: '#505F79',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  annualSalary: {
    background: '#FFFFFF',
    'box-shadow': '0px 2px 2px rgba(169, 169, 169, 0.25)',
    'border-radius': '6px',
    padding: '12px 8px',
    minWidth: 171,
    minHeight: 76,
    marginRight: '22px',
  },
  annualSalaryLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '16px',
    'line-height': '25px',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: '#505F79',
  },
  annualSalaryValue: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '24px',
    'line-height': '25px',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: activeTheme.general.salaryFontColor,
  },
  title: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '18px',
    'line-height': '25px',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: '#000000',
    marginBottom: theme.spacing(2),
  },
  description: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '25px',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    color: '#505F79',
    textAlign: 'left',
  },
  apply: {
    width: 156,
    height: '43px',
    marginTop: '29px',
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-weight': '500 !important',
    'font-size': '16px !important',
    'line-height': '24px !important',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
  applied: {
    width: 156,
    height: '43px',
    marginTop: '29px',
    backgroundColor: activeTheme.general.appliedJobBG,
    color: 'white  !important',
    borderRadius: '43px !important',
    'font-weight': '500 !important',
    'font-size': '16px !important',
    'line-height': '24px !important',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
  tagsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tags: {
    minWidth: '75px',
    ['@media (max-width: 800px)']: {
      minWidth: '50px',
    },
    ['@media (max-width: 600px)']: {
      minWidth: '50px',
    },
    height: '27px',
    background: activeTheme.general.tagBg,
    'border-radius': '3px',
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '25px',

    display: 'flex',
    'align-items': 'center',
    justifyContent: 'center',
    'letter-spacing': '0.127155px',
    'text-transform': 'capitalize',
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    color: '#FFFFFF',

    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  summaryLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '19px',
    color: '#000000',
  },
  summaryValue: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '19px',
    color: '#505F79',
  },
}));

export default function JobDetail() {
  const classes = useStyles();
  let params = useParams()
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    getJobDetails(params.id, user.id)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res.data[0])
          setJobDetails(res.data[0]);
          setLoading(false);
        } else {
          setJobDetails([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const getJobDetail = async () => {
    getJobDetails(params.id, user.id)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res.data[0])
          setJobDetails(res.data[0]);
          setLoading(false);
        } else {
          setJobDetails([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  return (
    <>
      {/* {params.id} */}
      {loading && <KenLoader />}
      {showUploadModal && (
        <UploadModal handleClose={() => setShowUploadModal(false)} handleJob={() => getJobDetail()} props={jobDetails} />
      )}
      <Grid container className={classes.root}>
        <Box display="flex" flexDirection="column" width="100%" >
          <Box
            width="100%"
            className={classes.header}
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box className={classes.companyLogo}>
                {jobDetails.attachments && jobDetails.attachments.slice(0, 1).map((item) => {
                  return <img src={`data:image/png;base64,` + item.body} alt={item.title} className={classes.jobLogo} />
                })}
              </Box>
              <Box
                display="flex"
                alignItems={'flex-start'}
                flexDirection="column"
              >
                <Typography className={classes.company}>
                  {jobDetails.organization && jobDetails.organization}
                </Typography>
                <Typography className={classes.employer}>
                  {jobDetails.jobRole && jobDetails.jobRole}
                </Typography>
                <Box display="flex" columnGap="8px" alignItems="center">
                  {/* <img src={data.flag} alt="" height={16} width={21} /> */}
                  <Typography className={classes.country}>
                    {jobDetails.country}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              rowGap={theme.spacing(2)}
              className={classes.annualSalary}
            >
              <Typography className={classes.annualSalaryLabel}>
                Annual Salary
              </Typography>
              <Typography className={classes.annualSalaryValue}>
                {jobDetails.salary && `₹ ` + jobDetails.salary}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="65%"
            >
              <Typography className={classes.title}>Job Description</Typography>
              <Typography className={classes.description}>
                <span
                  dangerouslySetInnerHTML={{ __html: jobDetails.description }}
                ></span>
              </Typography>
              {jobDetails.applied == "true" && <KenButton
                variant="primary"
                buttonClass={jobDetails.applied == "true" ? classes.applied : classes.apply}
                type="submit"
              >
                {jobDetails.applied == "true" ? 'Applied' : 'Apply Now'}
              </KenButton>
              }
              {jobDetails.applied == "false" && <KenButton
                variant="primary"
                buttonClass={jobDetails.applied == "true" ? classes.applied : classes.apply}
                type="submit"
                onClick={() => {
                  setShowUploadModal(true);
                }}
              >
                {jobDetails.applied == "true" ? 'Applied' : 'Apply Now'}
              </KenButton>
              }
            </Box>
            <Box
              width="30%"
              display="flex"
              flexDirection="column"
              marginLeft={theme.spacing(6)}
            >
              <Box
                display={'flex'}
                flexDirection="column"
                alignItems="flex-start"
                maxWidth={318}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    background: activeTheme.general.jobSummaryBg,
                    'border-radius': '7px 7px 0px 0px',
                    width: '100%',
                    padding: '12px 18px',

                    'font-weight': '600',
                    'font-size': '16px',
                    'line-height': '25px',
                    'letter-spacing': '0.127155px',
                    'text-transform': 'capitalize',
                    color: '#FFFFFF',
                  }}
                >
                  <Typography>Job Summary</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  sx={{
                    background: '#F7F7F7',
                    'border-radius': '7px 7px 0px 0px',
                    width: '100%',
                    padding: '18px 21px',
                  }}
                  rowGap={theme.spacing(2)}
                >
                  {jobDetails.location && <>
                    <Box
                      display={'flex'}
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        columnGap={theme.spacing(1)}
                      >
                        <img src={Location} alt="" />
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography className={classes.summaryLabel}>
                            Location
                          </Typography>
                          <Typography className={classes.summaryValue}>
                            {jobDetails.location && jobDetails.location}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                  }
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      columnGap={theme.spacing(1)}
                    >
                      <img src={Briefcase} alt="" />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography className={classes.summaryLabel}>
                          Job Type
                        </Typography>
                        <Typography className={classes.summaryValue}>
                          {jobDetails.jobType && jobDetails.jobType}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      columnGap={theme.spacing(1)}
                    >
                      <img src={Salary} alt="" />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography className={classes.summaryLabel}>
                          Salary
                        </Typography>
                        <Typography className={classes.summaryValue}>
                          {jobDetails.minSalary && `₹ ` + jobDetails.minSalary} {jobDetails.maxSalary && ` - ₹ ` + jobDetails.maxSalary}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      columnGap={theme.spacing(1)}
                    >
                      <img src={Clock} alt="" />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography className={classes.summaryLabel}>
                          Date Posted
                        </Typography>
                        <Typography className={classes.summaryValue}>
                          {jobDetails.createdDate && moment(jobDetails.createdDate).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  display={'flex'}
                  flexDirection="column"
                  alignItems="flex-start"
                  rowGap={theme.spacing(1)}
                  className={classes.tagsContainer}
                >
                  <Typography className={classes.title}>Tags</Typography>
                  <Box display="flex" flexWrap="wrap">
                    {jobDetails.category && jobDetails.category.split(',').map((tag, index) => (
                      <Box className={classes.tags}>
                        {tag}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
