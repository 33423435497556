import { Box, Grid, useTheme } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AuthContext } from "../../Context/authContext";
import Clock from '../../Assets/Svg/jobs/listing/time.svg';
import KenButton from '../../Components/KenButton';
import KenLoader from '../../Components/KenLoader';
import KenTabs from './tabs';
import LocationPin from '../../Assets/Svg/jobs/listing/location.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import UploadModal from '../JobDetail/UploadModal';
import { activeTheme } from '../../Utils/Themes/themeHelper';
import { getAllJobs } from '../../Utils/apiServices';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    'box-shadow': '0px 0px 30px #EFEFEF',
    'border-radius': '15px',
    padding: '22px 12px',
  },
  tabContainer: {
    background: '#FFFFFF',
    'box-shadow': '0px 0px 30px #EFEFEF',
    'border-radius': '15px',
  },
  tabItem: {
    '& .pill': {
      width: '34px',
      height: '20px',
      'border-radius': '18px',
      'font-weight': '400',
      'font-size': '12px',
      'line-height': '100%',
      display: 'flex',
      'align-items': 'center',
      'text-align': 'center',
      justifyContent: 'center',
      color: '#FFFFFF',

      '&.posted': {
        background: activeTheme.general.pillPostedBg,
        'font-style': 'normal',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '100%',
      },

      '&.applied': {
        'font-style': 'normal',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '100%',
        background: activeTheme.general.pillAppliedBg,
      },
    },
  },
  row: {
    borderBottom: '1px solid #DBDBDB',
    marginLeft: '10px',

    '& .logo': {
      marginRight: theme.spacing(5.8),
      minWidth: 100,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',

      '& .logoWrapper': {
        height: 40,
        width: 40,

        '& img': {
          height: 'auto',
          width: '100%',
          marginLeft: '-360px',
          ['@media (max-width: 800px)']: {
            marginLeft: '-360px'
          },
          ['@media (max-width: 600px)']: {
            marginLeft: '-317px'
          },
        },
      },

    },

    '& .company': {
      'font-style': 'normal',
      'font-weight': '600',
      'font-size': '20px',
      'line-height': '25px',
      'letter-spacing': '0.127155px',
      'text-transform': 'capitalize',
      marginTop: 'auto',
      marginLeft: '-180px',
      color: '#000000',
      marginBottom: theme.spacing(2),
      ['@media (max-width: 800px)']: {
        'font-size': '12px',
      },
      ['@media (max-width: 600px)']: {
        'font-size': '12px',
      },
    },

    '& .position': {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '20px',
      'letter-spacing': '0.127155px',
      'text-transform': 'capitalize',
      marginBottom: '25px',
      color: '#505F79',
      marginLeft: '-180px',
      ['@media (max-width: 800px)']: {
        'font-size': '12px',
      },
      ['@media (max-width: 600px)']: {
        'font-size': '12px',
      },
    },

    '& .tag': {
      background: '#F4F4F4',
      'border-radius': '2.81481px',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '10px',
      'line-height': '10px',
      'text-align': 'center',
      color: '#505F79',
      padding: '4px 6px',
      marginTop: 'auto'

    },

    '& .left-space': {
      marginLeft: theme.spacing(1.5),
    },

    '& .time': {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '10px',
      'line-height': '20px',
      'text-align': 'center',
      color: '#505F79',
      ['@media (max-width: 800px)']: {
        'font-size': '12px',
      },
      ['@media (max-width: 600px)']: {
        'font-size': '12px',
      },
      marginLeft: theme.spacing(2),
    },

    '& .type': {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '19px',
      color: '#505F79',
      marginRight: theme.spacing(2),
      ['@media (max-width: 800px)']: {
        'font-size': '12px !important',
      },
      ['@media (max-width: 600px)']: {
        'font-size': '12px !important',
      },
    },

    '& .location': {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '19px',
      color: '#505F79',
      ['@media (max-width: 800px)']: {
        'font-size': '12px',
      },
      ['@media (max-width: 600px)']: {
        'font-size': '12px',
      },
    },
  },
  know: {
    width: '110px',
    height: '30px !important',
    background: 'white',
    borderRadius: '43px !important',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '12px !important',
    'line-height': '18px !important',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
    color: activeTheme.general.knowMoreText,
    'box-shadow': '0px 2px 2px rgba(169, 169, 169, 0.25) !important',
    'border-radius': '43px !important',
    ['@media (max-width: 800px)']: {
      width: '90px',
      marginLeft: '45px'
    },
    ['@media (max-width: 600px)']: {
      width: '90px',
    },
    '&:hover': {
      background: 'white',
    },
  },
  apply: {
    width: '110px',
    height: '30px !important',
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '12px !important',
    'line-height': '18px !important',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
    marginLeft: theme.spacing(4),
    ['@media (max-width: 800px)']: {
      width: '72px',
    },
    ['@media (max-width: 600px)']: {
      width: '72px',
    },
  },
  applied: {
    width: '110px',
    height: '30px !important',
    backgroundColor: activeTheme.general.appliedJobBG,
    color: 'white  !important',
    borderRadius: '43px !important',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '12px !important',
    'line-height': '18px !important',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
    marginLeft: theme.spacing(4),
  },
  jobLogo: {
    width: 100,
  },
}));

function LogoText({ label, src }) {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems={'center'}>
      <Box sx={{ marginRight: theme.spacing(1) }}>
        <img src={src} alt="" />
      </Box>
      <Typography className="type">{label}</Typography>
    </Box>
  );
}

function Row(props) {
  const { row } = props;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <>{showUploadModal && (
      <UploadModal handleClose={() => {
        setShowUploadModal(false);
        props.refetch();
      }} props={row} />
    )}
      <TableRow className={classes.row}>
        <TableCell className="cell">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Box display="flex" alignItems="stretch">
              <Box className="logo" alignSelf={'center'} onClick={() => {
                navigate(`/job/${row.recordId}`);
              }}>
                <Box className='logoWrapper'>
                  {row.attachments.slice(0, 1).map((item) => {
                    return <img src={`data:image/png;base64,` + item.body} alt={item.title} />
                  })}
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                sx={{
                  minHeight: 58, minWidth: '180px', ['@media (max-width: 800px)']: {
                    marginLeft: '-18px'
                  }, ['@media (max-width: 600px)']: {
                    marginLeft: '-18px',
                  },
                }}
              >
                <Typography className="company">{row.organization}</Typography>
                <Typography className="position">{row.name}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                sx={{
                  marginLeft: theme.spacing(-20), minHeight: 58, ['@media (max-width: 800px)']: {
                    marginLeft: theme.spacing(-25)
                  }, ['@media (max-width: 600px)']: {
                    marginLeft: theme.spacing(-39)
                  }
                }}
              >
                <Box display="flex" alignItems={'center'}>
                  {row.category && row.category.split(',').map((tag, index) => (
                    <Box className={`tag ${index > 0 ? 'left-space' : ''}`}>
                      {tag}
                    </Box>
                  ))}
                  {row.createdDate && <Typography className="time">{moment(row.createdDate).fromNow()}</Typography>}
                </Box>
                <Box style={{ display: 'flex', marginLeft: '10px', marginBottom: '30px', }}>
                  {row.jobType && <LogoText label={row.jobType} src={Clock} />}
                  {row.location && <LogoText label={row.location} src={LocationPin} />}
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <KenButton
                variant="primary"
                buttonClass={classes.know}
                type="submit"
                onClick={() => {
                  navigate(`/job/${row.recordId}`);
                }}
              >
                Know More
              </KenButton>
              {row.applied == "true" && <KenButton
                variant="primary"
                buttonClass={row.applied == "true" ? classes.applied : classes.apply}
                type="submit"
              >
                {row.applied == "true" ? 'Applied' : 'Apply Now'}
              </KenButton>
              }
              {row.applied == "false" && <KenButton
                variant="primary"
                buttonClass={row.applied == "true" ? classes.applied : classes.apply}
                type="submit"
                onClick={() => {
                  setShowUploadModal(true);
                }}
              >
                {row.applied == "true" ? 'Applied' : 'Apply Now'}
              </KenButton>
              }
            </Box>
          </Box>
        </TableCell>
      </TableRow >
    </>
  );
}

export default function Jobs() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [allJobs, setAllJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    loadJobs();
  }, []);

  const loadJobs = () => {
    setLoading(true);
    getAllJobs(user?.alumniId)
      .then((res) => {
        if (res?.data?.length > 0) {
          setAllJobs([...res.data]);
          setLoading(false);
        } else {
          setAllJobs([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  const tabHandleChange = (active) => {
    setActiveTab(active);
  };

  return (
    <>
      {loading && <KenLoader />}
      <Box xs={{ paddingBottom: theme.spacing(2), marginLeft: '-40px' }}>
        <Grid container>
          <Grid item container xs={11} className={classes.root}>
            <Grid item xs={12} classNAme={classes.tabContainer}>
              <KenTabs
                active={activeTab}
                tabs={[
                  {
                    label: (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.tabItem}
                      >
                        <Box>POSTED</Box>
                        <Box
                          as="span"
                          sx={{ marginLeft: theme.spacing(1) }}
                          className={`pill posted`}
                        >
                          {allJobs.length}
                        </Box>
                      </Box>
                    ),
                    value: 0,
                  },
                  {
                    label: (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.tabItem}
                      >
                        <Box>APPLIED</Box>
                        <Box
                          as="span"
                          sx={{ marginLeft: theme.spacing(1) }}
                          className={`pill applied`}
                        >
                          {allJobs.filter((r) => (r.applied == "true")).length}
                        </Box>
                      </Box>
                    ),
                    value: 1,
                  },
                ]}
                tabHandleChange={tabHandleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="table">
                  <TableBody>
                    {allJobs
                      .filter((r) => (activeTab === 1 ? r.applied == "true" : true))
                      .map((row) => (
                        <Row key={row.recordId} row={row} refetch={loadJobs} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
