import './App.css';
import 'react-calendar/dist/Calendar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AppContextProvider } from './Context/appContext';
import { AuthContextProvider } from './Context/authContext';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import MainLayout from './Containers/MainLayout';
import React from 'react';
import ThemeProvider from './Utils/themeProvider';
import i18n from './Utils/i18next/i18n.js';

function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <AuthContextProvider>
          <ThemeProvider>
            <I18nextProvider i18n={i18n}>
              <div className="App">
                <MainLayout />
              </div>
            </I18nextProvider>
          </ThemeProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
