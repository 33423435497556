const routes = {
  login: '/login',
  signup: '/signup',
  loginOTP: '/login-otp',
  verifyNumber: '/verify-number',
  verifyEmail: '/verify-email',
  verifiedOTP: '/otp-verified',
  index: '/index',
  // home: '/home',
  home: '/evaluation',
  events: '/events',
  eventDetail: '/event/:id',
  alumniOfMonthProfile: '/alumni-of-month-profile/:id',
  jobs: '/jobs',
  jobDetail: '/job/:id',
  eventDetails: '/event-details/:id',
  alumniProfile: '/profile/:id',
  dashboard: 'dashboard',
  startup: 'startup-profile',
  // evaluation:'/evaluation'
};
export default routes;
