import { SubTitle, Title } from '../common';

import { Box } from '@mui/material';
import KenButton from '../../../Components/KenButton';
import { LOGIN_BG } from '../Login';
import MobileLoginBG from '../../../Assets/Svg/login/login-mobile-bg.svg';
import React from 'react';
import SuccessTick from '../../../Assets/Svg/green-tick.svg';
import { activeTheme } from '../../../Utils/Themes/themeHelper';
import { makeStyles } from '@mui/styles';
import routes from '../../../Constants/routes';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LOGIN_BG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
  loginContent: {
    minWidth: '500px',
    minHeight: '520px',
    'box-shadow':
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '16px',
    padding: '48px 84px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: '8px',
      minWidth: '360px',
    },
  },
  otpContent: {
    minHeight: '375px',
    maxWidth: '220px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '16px',
    color: '#8BC349',
    fontSize: '32px',
    lineHeight: '27px',
    fontWeight: 600,
    marginTop: '24px',
  },
  subTitle: {
    marginBottom: '32px',
    fontSize: '18px',
    lineHeight: '180%',
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: activeTheme.buttonPrimary.backgroundColor,
    color: activeTheme.buttonPrimary.textColor,
    borderRadius: '43px !important',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    display: 'flex',
    'align-items': 'center',
    'text-align': 'center',
  },
}));
export default function VerifiedOTP() {
  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(routes.loginOTP);
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={onSubmit}>
        <Box
          className={classes.loginContent}
          display={'flex'}
          flexDirection="column"
          justifyContent={'flex-start'}
          alignItems="flex-start"
        >
          <Box className={classes.otpContent}>
            <img src={SuccessTick} alt="success" />
            <Title className={classes.title} text="Awesome!" />
            <SubTitle
              className={classes.subTitle}
              text="Your account has been created successfully."
            />
          </Box>

          <KenButton
            variant="primary"
            buttonClass={classes.verify}
            type="submit"
            onClick={() => navigate(routes.login)}
          >
            Log In to Continue
          </KenButton>
        </Box>
      </form>
    </Box>
  );
}
