import React, { useContext, useEffect, useState } from 'react';
import { requestForOTP, verifyOTP } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../Context/authContext';
import { Box } from '@mui/material';
import { LOGIN_BG } from '../Login';
import MobileLoginBG from '../../../Assets/Svg/login/login-mobile-bg.svg';
import Verify from '../common/Verify';
import { handleError } from '../util';
import { makeStyles } from '@mui/styles';
import routes from '../../../Constants/routes';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LOGIN_BG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ['@media (max-width: 600px)']: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      background: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
}));
export default function VerifyEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!navigateState?.id) {
      navigate(routes.signup);
    }
  }, [navigate, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.email,
      });

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: navigateState.id,
            alumniEmail: res?.alumniEmail,
            alumniId: res?.alumniId,
            alumniName: res?.alumniName,
            yearOfGraduation: res?.yearOfGraduation,
          },
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container}>
      <form>
        <Verify
          loading={loading}
          from="verifyEmail"
          title="Verify Your Email ID"
          subTitle={
            <>
              <span>
                Enter the OTP sent to your registered email. Don’t forget to check your spam folder!
              </span>
            </>
          }
          submitText="Verify & Sign Up"
          onVerify={(otp) => {
            onSubmit({ otp });
          }}
          resendOTP={async () => {
            await requestForOTP({ id: navigateState.email });
          }}
        ></Verify>
      </form>
    </Box>
  );
}
